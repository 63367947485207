import React, { useContext, useEffect, useState } from 'react';
import { emailRegEx, HELPING_FILENAMES } from '@utils/utils';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@components/Checkbox';
import {
  isCompanyAlreadyRegistered,
  register,
  registerStepSetPassword,
} from '@models/auth/apiRequests/authRequests';
import { toast } from 'react-toastify';
import {
  formatAddress,
  SIGNUP_STEPS,
  signUpStepDescription,
  signUpStepLabel,
} from '@models/subscription/utils/utils';
import { getCompanyDataWithSiret } from '@models/subscription/apiRequests/ subscriptionRequests';
import { AuthContext } from '@models/auth/utils/authContext';
import SpinLoader from '@components/SpinLoader';
import TagManager from 'react-gtm-module';
import FloatingLabelInput from '../components/FloatingLabelInput';

function SignUp(): JSX.Element {
  const navigate = useNavigate();

  const { updateToken } = useContext(AuthContext);

  const [siretNumber, setSiretNumber] = useState<string>('');
  const [step, setStep] = useState<number>(SIGNUP_STEPS.SIRET);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyNafCode, setCompanyNafCode] = useState<string>('');
  const [companyLegalForm, setCompanyLegalForm] = useState<string>('');
  const [companyAddressFormatted, setCompanyAddressFormatted] =
    useState<string>('');
  const [companyAddressStreet, setCompanyAddressStreet] = useState<string>('');
  const [companyAddressComplement, setCompanyAddressComplement] =
    useState<string>('');
  const [companyAddressPostcode, setCompanyAddressPostcode] =
    useState<string>('');
  const [companyAddressCity, setCompanyAddressCity] = useState<string>('');
  const [companyCreationDate, setCompanyCreationDate] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userFirstName, setUserFirstName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [validateConditionIsChecked, setValidateCondition] =
    useState<boolean>(false);
  const [userPassword, setUserPassword] = useState<string>('');
  const [userPasswordConfirmation, setUserPasswordConfirmation] =
    useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [errorSiret, setErrorSiret] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const queryEmail = queryParams.get('email');

  useEffect(() => {
    if (token && queryEmail) {
      setUserEmail(queryEmail);
      setStep(SIGNUP_STEPS.PASSWORD);
    }
  }, [token, queryEmail]);

  const handleCheckSiret = async () => {
    setLoading(true);
    const isRegistered = await isCompanyAlreadyRegistered(siretNumber);
    if (isRegistered) {
      setErrorSiret(
        'Ce numéro de SIRET est déjà utilisé, veuillez vous connecter'
      );
      setLoading(false);
      return;
    }

    setLoading(true);
    const companyData = await getCompanyDataWithSiret(siretNumber);

    if (!companyData) {
      setErrorSiret('Numéro de SIRET invalide');
      setLoading(false);
      return;
    }
    setCompanyName(companyData.name);
    setCompanyNafCode(companyData.naf_code);
    setCompanyLegalForm(companyData.legal_form);
    setCompanyAddressStreet(companyData.address.street);
    setCompanyAddressComplement(companyData.address.complement);
    setCompanyAddressPostcode(companyData.address.postcode);
    setCompanyAddressCity(companyData.address.city);
    setCompanyAddressFormatted(
      formatAddress(
        `${companyData.address.street} ${companyData.address.complement}, ${companyData.address.postcode} ${companyData.address.city}`
      )
    );
    setCompanyCreationDate(companyData.incorporation_date);
    setStep(SIGNUP_STEPS.COMPANY);
    setLoading(false);
  };

  const handleValidateEmailAddress = async () => {
    // Vous pouvez remplacer cette fonction par la logique de validation de l'adresse email
    setStep(SIGNUP_STEPS.ACCOUNT_VALIDATION);
  };

  const handleValidateCondition = async () => {
    setLoading(true);
    const success = await register({
      authentication: {
        email: userEmail,
        name: userName,
        first_name: userFirstName,
      },
      company: {
        name: companyName,
        siret: siretNumber,
        naf_code: companyNafCode,
        legal_form: companyLegalForm,
        incorporation_date: companyCreationDate,
        address: {
          street: companyAddressStreet,
          complement: companyAddressComplement,
          postcode: companyAddressPostcode,
          city: companyAddressCity,
        },
      },
    });
    if (success) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'sign_up',
          user_id: localStorage.getItem('user_unique_id'),
          visit_count: localStorage.getItem('visit_count'),
          user_data: {
            email: userEmail,
            name: userName,
            first_name: userFirstName,
          },
          company: {
            name: companyName,
            siret: siretNumber,
            naf_code: companyNafCode,
            legal_form: companyLegalForm,
            incorporation_date: companyCreationDate,
            address: {
              street: companyAddressStreet,
              complement: companyAddressComplement,
              postcode: companyAddressPostcode,
              city: companyAddressCity,
            },
          },
        },
        dataLayerName: 'PageDataLayer',
      };
      localStorage.setItem('visit_count', '0');
      TagManager.dataLayer(tagManagerArgs);
      toast.success(
        'Un email de confirmation vous a été envoyé pour finaliser votre inscription'
      );
      navigate('/', { replace: true });
    }
    setLoading(false);
  };

  const handlePasswordValidation = async () => {
    if (userPassword !== userPasswordConfirmation) {
      setErrorPassword('Les mots de passe ne correspondent pas');
    } else {
      setErrorPassword('');
      setLoading(true);
      const success = await registerStepSetPassword(
        {
          email: userEmail,
          password: userPassword,
          password_confirmation: userPassword,
          token: token || '',
        },
        updateToken
      );
      if (success) {
        navigate('/', { replace: true });
      }
      setLoading(false);
    }
  };

  return (
    <div className="lg:flex justify-center h-full items-center">
      <div
        className={`lg:w-1/2 w-full flex justify-center ${
          step === SIGNUP_STEPS.SIRET || step === SIGNUP_STEPS.PASSWORD
            ? 'lg:mt-[6rem]'
            : 'lg:mt-0'
        } mt-8 flex space-x-12 self-start\`}`}
      >
        <div className="lg:w-[28.75rem] w-full lg:py-14 lg:px-12 py-7 px-6 border border-secondary-400 rounded">
          <h2 className="text-center font-epilogue font-bold text-[2rem] text-neutral-900 leading-[3rem] mb-2">
            {signUpStepLabel(step)}
          </h2>
          <div className="flex justify-center">
            <p className="text-neutral-500 italic mb-8 w-[20.5rem]">
              {signUpStepDescription(step)}
            </p>
          </div>
          <div className="flex flex-col space-y-6">
            {step === SIGNUP_STEPS.SIRET && (
              <>
                <FloatingLabelInput
                  id="siretNumber"
                  name="siretNumber"
                  type="text"
                  placeholder="Numéro de SIRET"
                  value={siretNumber}
                  setValue={setSiretNumber}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && siretNumber.length === 14) {
                      handleCheckSiret();
                    }
                  }}
                />
                {errorSiret && (
                  <span className="text-xs text-red-500">{errorSiret}</span>
                )}
                <button
                  disabled={siretNumber.length !== 14}
                  onClick={handleCheckSiret}
                  type="button"
                  className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
                >
                  Trouver mon entreprise
                </button>
              </>
            )}
            {/* Vous pouvez ajouter les autres étapes ici en utilisant le même modèle que l'étape 1 */}
            {(step === SIGNUP_STEPS.COMPANY ||
              step === SIGNUP_STEPS.ACCOUNT_VALIDATION) && (
              <>
                <FloatingLabelInput
                  id="siretNumber"
                  name="siretNumber"
                  type="text"
                  placeholder="Numéro de SIRET"
                  value={siretNumber}
                  setValue={setSiretNumber}
                  readOnly
                />
                <FloatingLabelInput
                  id="companyName"
                  name="companyName"
                  type="text"
                  placeholder="Nom de l'entreprise"
                  value={companyName}
                  setValue={setCompanyName}
                  readOnly={false}
                />
                <FloatingLabelInput
                  id="companyAddress"
                  name="companyAddress"
                  type="text"
                  placeholder="Adresse"
                  value={
                    companyAddressFormatted.trim().length === 1
                      ? companyAddressStreet
                      : companyAddressFormatted
                  }
                  setValue={setCompanyAddressStreet}
                  readOnly={companyAddressFormatted.trim().length > 1}
                />
                <FloatingLabelInput
                  id="companyCreationDate"
                  name="companyCreationDate"
                  type="text"
                  placeholder="Date de création de l'entreprise"
                  value={companyCreationDate}
                  setValue={setCompanyCreationDate}
                  readOnly
                />
                <FloatingLabelInput
                  id="userName"
                  name="userName"
                  type="text"
                  placeholder="Votre nom"
                  value={userName}
                  setValue={setUserName}
                  readOnly={step === SIGNUP_STEPS.ACCOUNT_VALIDATION}
                />
                <FloatingLabelInput
                  id="userFirstName"
                  name="userFirstName"
                  type="text"
                  placeholder="Votre prénom"
                  value={userFirstName}
                  setValue={setUserFirstName}
                  readOnly={step === SIGNUP_STEPS.ACCOUNT_VALIDATION}
                />
                <FloatingLabelInput
                  id="userEmail"
                  name="userEmail"
                  type="email"
                  placeholder="Email"
                  value={userEmail}
                  setValue={setUserEmail}
                  readOnly={step === SIGNUP_STEPS.ACCOUNT_VALIDATION}
                />
                {step === SIGNUP_STEPS.COMPANY && (
                  <button
                    disabled={
                      userEmail.length === 0 || !emailRegEx.test(userEmail)
                    }
                    onClick={handleValidateEmailAddress}
                    type="button"
                    className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
                  >
                    Valider ces informations
                  </button>
                )}
                {step === SIGNUP_STEPS.ACCOUNT_VALIDATION && (
                  <>
                    <Checkbox
                      checked={validateConditionIsChecked}
                      label="Je valide les conditions présentes dans le "
                      labelLink="document d'entrée en relation"
                      fileLinked={HELPING_FILENAMES.adequacyReport}
                      onChange={() =>
                        setValidateCondition(!validateConditionIsChecked)
                      }
                    />
                    <button
                      disabled={!validateConditionIsChecked}
                      onClick={handleValidateCondition}
                      type="button"
                      className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
                    >
                      Envoyer email de confirmation
                    </button>
                  </>
                )}
              </>
            )}
            {step === SIGNUP_STEPS.PASSWORD && (
              <>
                <FloatingLabelInput
                  id="userEmail"
                  name="userEmail"
                  type="text"
                  placeholder="Identifiant"
                  value={userEmail}
                  setValue={setUserEmail}
                  readOnly
                />
                <FloatingLabelInput
                  id="userPassword"
                  name="userPassword"
                  type="password"
                  placeholder="Nouveau mot de passe"
                  value={userPassword}
                  setValue={setUserPassword}
                />
                <FloatingLabelInput
                  id="userPasswordConfirmation"
                  name="userPasswordConfirmation"
                  type="password"
                  placeholder="Confirmez votre mot de passe"
                  value={userPasswordConfirmation}
                  setValue={setUserPasswordConfirmation}
                />
                {errorPassword && (
                  <span className="text-xs text-red-500">{errorPassword}</span>
                )}
                <button
                  onClick={handlePasswordValidation}
                  type="button"
                  className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
                >
                  Valider le mot de passe
                </button>
              </>
            )}
            {loading && (
              <div className="flex justify-center">
                <SpinLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
