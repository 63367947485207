import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoPoddle from '@assets/images/logo_poddle.png';

function HeaderAuth(): JSX.Element {
  const location = useLocation();

  return (
    <div className="w-full flex flex-col lg:flex-row lg:justify-between items-center lg:pb-6 lg:px-8 pt-6">
      <Link to="/">
        <img className="w-[6.5625rem]" src={logoPoddle} alt="Logo Poddle" />
      </Link>
      <div className="text-neutral-700 text-base lg:flex text-right">
        {location.pathname === '/signup' ? (
          <>
            <div className="hidden lg:block">
              Vous avez déjà un compte ?{'\u00A0'}
            </div>
            <Link to="/login" className="text-primary-500 hover:underline">
              Sign in
            </Link>
          </>
        ) : (
          <>
            <div className="hidden lg:block">
              Vous n&apos;avez pas de compte ?{'\u00A0'}
            </div>
            <Link to="/signup" className="text-primary-500 hover:underline">
              Sign up
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default HeaderAuth;
