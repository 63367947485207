import { createContext, Dispatch, SetStateAction } from 'react';

export interface AccountInitializationContextState {
  step: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  updateStep: Dispatch<SetStateAction<0 | 1 | 2 | 3 | 4 | 5 | 6>>;
  amountToInvest: string;
  updateAmountToInvest: Dispatch<SetStateAction<string>>;
}

export const AccountInitializationContext = createContext(
  {} as AccountInitializationContextState
);
