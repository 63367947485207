import React, { useContext, useState } from 'react';
import SimpleInput from '@components/SimpleInput';
import Checkbox from '@components/Checkbox';
import Cta from '@components/Cta';
import Slider from '@components/Slider';
import CustomSelect from '@components/CustomSelect';
import { setCustomerIdentityDetails } from '@models/accountInitialization/apiRequests/ subscriptionRequests';
import { AccountInitializationContext } from '../utils/accountInitializationContext';
import { fiscalEntityTypes } from '../utils/utils';

function InvestorProfile(): JSX.Element {
  const { updateStep } = useContext(AccountInitializationContext);

  const [partBusinessGroup, setPartBusinessGroup] = useState<boolean>(false);
  const [businessGroupName, setBusinessGroupName] = useState<string>('');

  const [haveDifferentAddress, setHaveDifferentAddress] =
    useState<boolean>(false);
  const [differentAddress, setDifferentAddress] = useState<string>('');
  const [differentAddressComplement, setDifferentAddressComplement] =
    useState<string>('');
  const [differentAddressPostcode, setDifferentAddressPostcode] =
    useState<string>('');
  const [differentAddressCity, setDifferentAddressCity] = useState<string>('');

  const [haveBearerBonds, setHaveBearerBonds] = useState<boolean>(false);
  const [bearerBondsPercent, setBearerBondsPercent] = useState<number>(50);

  const [haveFinancialExp, setHaveFinancialExp] = useState<boolean>(false);

  const [isDirector, setIsDirector] = useState<boolean>(false);

  const [fiscalEntityType, setFiscalEntityType] = useState<string>(
    fiscalEntityTypes[0]
  );

  const handleSubmit = async () => {
    const response = await setCustomerIdentityDetails({
      business_group: partBusinessGroup,
      business_group_name: businessGroupName,
      different_address: haveDifferentAddress,
      address: {
        street: differentAddress,
        complement: differentAddressComplement,
        postcode: differentAddressPostcode,
        city: differentAddressCity,
      },
      composed_bearer_bond: haveBearerBonds,
      bearer_bond_percentage: bearerBondsPercent,
      finance_profession: haveFinancialExp,
      manager_stock_exchange_company: isDirector,
      legal_form: fiscalEntityType,
    });
    if (response) {
      updateStep(5);
    }
  };

  const businessGroupBlock = (
    <>
      <div className="flex space-x-4">
        <div className="text-left w-3/4">
          Est-ce que vous appartenez à un groupe d&apos;affaire ?
        </div>
        <div className="flex space-x-2 justify-end flex-grow">
          <Checkbox
            checked={partBusinessGroup}
            label="oui"
            onChange={() => setPartBusinessGroup(true)}
            small
          />
          <Checkbox
            checked={!partBusinessGroup}
            label="non"
            onChange={() => setPartBusinessGroup(false)}
            small
          />
        </div>
      </div>
      {partBusinessGroup && (
        <div className="flex space-x-4">
          <div>Si oui, lequel ?</div>
          <div className="flex-grow">
            <SimpleInput
              setValue={(value) => setBusinessGroupName(value)}
              name="businessGroupName"
              id="businessGroupName"
              placeholder="Nom du groupe d'affaire"
              value={businessGroupName}
              type="text"
              small
            />
          </div>
        </div>
      )}
    </>
  );

  const differentAddressBlock = (
    <>
      <div className="flex space-x-4 pt-4">
        <div className="text-left w-3/4">
          Avez vous une adresse d&apos;établissement différente de votre adresse
          fiscale ?
        </div>
        <div className="flex space-x-2 justify-end flex-grow">
          <Checkbox
            checked={haveDifferentAddress}
            label="oui"
            onChange={() => setHaveDifferentAddress(true)}
            small
          />
          <Checkbox
            checked={!haveDifferentAddress}
            label="non"
            onChange={() => setHaveDifferentAddress(false)}
            small
          />
        </div>
      </div>
      {haveDifferentAddress && (
        <div className="flex space-x-4">
          <div>Si oui, renseignez-la</div>
          <div className="flex-grow space-y-2">
            <SimpleInput
              setValue={(value) => setDifferentAddress(value)}
              name="differentAddress"
              id="differentAddress"
              placeholder="Adresse"
              value={differentAddress}
              type="text"
              small
            />
            <SimpleInput
              setValue={(value) => setDifferentAddressComplement(value)}
              name="differentAddressComplement"
              id="differentAddressComplement"
              placeholder="Complément d'adresse"
              value={differentAddressComplement}
              type="text"
              small
            />
            <SimpleInput
              setValue={(value) => setDifferentAddressPostcode(value)}
              name="differentAddressPostcode"
              id="differentAddressPostcode"
              placeholder="Code postal"
              value={differentAddressPostcode}
              type="text"
              small
            />
            <SimpleInput
              setValue={(value) => setDifferentAddressCity(value)}
              name="differentAddressCity"
              id="differentAddressCity"
              placeholder="Ville"
              value={differentAddressCity}
              type="text"
              small
            />
          </div>
        </div>
      )}
    </>
  );

  const bearerBondsBlock = (
    <>
      <div className="flex space-x-4 pt-4">
        <div className="text-left w-3/4">
          La structure est elle composée de bons au porteur ?
        </div>
        <div className="flex space-x-2 justify-end flex-grow">
          <Checkbox
            checked={haveBearerBonds}
            label="oui"
            onChange={() => setHaveBearerBonds(true)}
            small
          />
          <Checkbox
            checked={!haveBearerBonds}
            label="non"
            onChange={() => setHaveBearerBonds(false)}
            small
          />
        </div>
      </div>
      {haveBearerBonds && (
        <div className="flex space-x-4">
          <div>Si oui, pour quel % ?</div>
          <div className="flex-grow flex items-center">
            <Slider
              defaultValue={bearerBondsPercent}
              onChange={(value) => setBearerBondsPercent(value)}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="w-full lg:w-[32rem] mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup lg:p-[1.75rem] p-4 space-y-4">
      <h2 className="text-neutral-700 font-epilogue font-bold text-[1.75rem] leading-[3rem]">
        Profil d&apos;investisseur
      </h2>
      {businessGroupBlock}
      {differentAddressBlock}
      {bearerBondsBlock}
      <div className="flex space-x-4 pt-4">
        <div className="text-left w-3/4">
          Exercez vous ou avez-vous exercé une profession dans le domaine
          financier ou boursier ?
        </div>
        <div className="flex space-x-2 justify-end flex-grow">
          <Checkbox
            checked={haveFinancialExp}
            label="oui"
            onChange={() => setHaveFinancialExp(true)}
            small
          />
          <Checkbox
            checked={!haveFinancialExp}
            label="non"
            onChange={() => setHaveFinancialExp(false)}
            small
          />
        </div>
      </div>
      <div className="flex space-x-4 pt-4">
        <div className="text-left w-3/4">
          Êtes-vous dirigeant ou salarié d&apos;une société côté ? Ou de la
          maison mère d&apos;une société côtée ?
        </div>
        <div className="flex space-x-2 justify-end flex-grow">
          <Checkbox
            checked={isDirector}
            label="oui"
            onChange={() => setIsDirector(true)}
            small
          />
          <Checkbox
            checked={!isDirector}
            label="non"
            onChange={() => setIsDirector(false)}
            small
          />
        </div>
      </div>
      <div className="flex space-x-4 pt-4 items-center">
        <div className="text-left">
          Veuillez sélectionner le type d&apos;entité fiscale:
        </div>
        <div className="flex justify-end flex-grow">
          <CustomSelect
            options={fiscalEntityTypes}
            value={fiscalEntityType}
            onChange={(type) => setFiscalEntityType(type)}
          />
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Cta
          onClick={() => {
            handleSubmit();
          }}
          label="Envoyer"
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default InvestorProfile;
