import React, { useContext } from 'react';
import { deposit } from '@models/depositsAndWithdrawals/apiRequests/depositsAndWithdrawalsRequests';
import { AccountInitializationContext } from '../utils/accountInitializationContext';
import AmountDeposit from '../../depositsAndWithdrawals/components/deposits/AmountDeposit';
import { GlobalContext } from '../../../context/globalContext';

function AmountToInvest(): JSX.Element {
  const { updateAmountToInvest, updateStep } = useContext(
    AccountInitializationContext
  );
  const { updateBalance } = useContext(GlobalContext);

  return (
    <AmountDeposit
      ctaValidateOnClick={async (amount) => {
        updateAmountToInvest(amount);
        const depositSuccess = await deposit(
          {
            amount: parseInt(amount, 10),
            transfer_mode: 'WIRE',
          },
          updateBalance
        );
        if (depositSuccess) {
          updateStep(2);
        }
      }}
      title="Définir le montant à placer"
    />
  );
}

export default AmountToInvest;
