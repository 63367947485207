import React from 'react';
import Cta from '@components/Cta';

interface CardWithImageProps {
  image?: string;
  title: string;
  text: string;
  ctaLabel: string;
  ctaOnClick: () => void;
  ctaDisabled?: boolean;
}

function CardWithImage({
  image,
  title,
  text,
  ctaLabel,
  ctaOnClick,
  ctaDisabled,
}: CardWithImageProps): JSX.Element {
  return (
    <div className="w-[16.75rem] p-[1rem] rounded bg-white shadow-md flex flex-col justify-between">
      <div>
        <div
          style={{ paddingTop: '65.625%' }}
          className="relative mb-4 bg-gray-300 rounded z-0"
        >
          {image && (
            <img
              src={image}
              alt=""
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>
        <h2 className="mb-2 text-[1.125rem] leading-[1.75rem] font-inter font-bold">
          {title}
        </h2>
        <p className="mb-2 text-[.75rem] leading-[1.25rem]">{text}</p>
      </div>
      <div className="flex justify-center mb-2">
        <Cta
          onClick={ctaOnClick}
          label={ctaLabel}
          disabled={ctaDisabled}
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default CardWithImage;

CardWithImage.defaultProps = {
  image: undefined,
  ctaDisabled: false,
};
