import React from 'react';
import { truncate } from '@utils/utils';

type CtaProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  transparent?: boolean;
  outline?: boolean;
  grey?: boolean;
  Icon?: JSX.Element;
  addClass?: string;
};

function Cta({
  label,
  onClick,
  disabled,
  small,
  outline,
  transparent,
  grey,
  Icon,
  addClass,
}: CtaProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        small ? 'px-2' : 'px-4'
      } button lg:!w-min py-2 rounded-[4px] ${
        // eslint-disable-next-line no-nested-ternary
        transparent || grey
          ? `hover:text-primary-500 ${
              transparent
                ? 'bg-white text-neutral-500'
                : 'bg-[#EAECEF] text-neutral-600'
            }`
          : outline
          ? 'text-primary-500 bg-white border border-primary-500 hover:bg-primary-500 hover:text-white'
          : 'text-white bg-primary-500 hover:bg-primary-450 active:bg-primary-400'
      } disabled:opacity-40 lg:!whitespace-nowrap group flex justify-center items-center ${addClass}`}
      disabled={disabled}
    >
      {Icon && Icon}
      <span className={`${Icon ? 'ml-2' : ''}`}>
        {label.length > 35 ? truncate(label, 35) : label}
      </span>
    </button>
  );
}

export default Cta;

Cta.defaultProps = {
  Icon: undefined,
  disabled: false,
  small: false,
  transparent: false,
  grey: false,
  addClass: '',
  outline: false,
};
