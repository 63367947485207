import React, { useEffect, useState } from 'react';
import { SvgIconProps } from '@mui/material';

interface TipPopupProps {
  IconComponent: (props: SvgIconProps) => JSX.Element;
  title: string;
  content: string;
  multiplePosition?: 1 | 2;
}

function TipPopup({
  IconComponent,
  title,
  content,
  multiplePosition,
}: TipPopupProps): JSX.Element {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div
      className={`absolute w-[22rem] flex right-[4rem] top-1/2 ${
        // eslint-disable-next-line no-nested-ternary
        !multiplePosition
          ? '-translate-y-1/2'
          : multiplePosition === 1
          ? '-translate-y-1/2'
          : 'translate-y-1/2'
      } flex bg-white rounded shadow-infos-popup transition-all duration-500 transform ${
        animate ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="bg-success-200 p-2 flex items-center justify-center">
        <IconComponent
          style={{ fill: '#17A948', width: '2rem', height: '2rem' }}
        />
      </div>
      <div className="px-3 py-6 flex-grow">
        <h2 className="text-neutral-900 text-xl font-epilogue font-semibold mb-2">
          {title}
        </h2>
        <p className=" text-neutral-500 text-sm">{content}</p>
      </div>
    </div>
  );
}

export default TipPopup;

TipPopup.defaultProps = {
  multiplePosition: undefined,
};
