// eslint-disable-next-line no-shadow
export enum SIGNUP_STEPS {
  SIRET,
  COMPANY,
  ACCOUNT_VALIDATION,
  PASSWORD,
}

export const signUpStepLabel = (step: number) => {
  switch (step) {
    case SIGNUP_STEPS.SIRET:
      return 'Bienvenue !';
    case SIGNUP_STEPS.COMPANY:
      return 'Votre entreprise';
    case SIGNUP_STEPS.ACCOUNT_VALIDATION:
      return 'Validez votre compte';
    case SIGNUP_STEPS.PASSWORD:
      return 'Renseignez votre mot de passe';
    default:
      return '';
  }
};

export const signUpStepDescription = (step: number) => {
  switch (step) {
    case SIGNUP_STEPS.SIRET:
      return "Renseignez votre numéro de SIRET et on s'occupe du reste";
    case SIGNUP_STEPS.COMPANY:
      return 'Veuillez renseigner les informations de votre entreprise afin de valider la création de votre compte.';
    case SIGNUP_STEPS.ACCOUNT_VALIDATION:
      return "Veuillez valider les conditions présentes dans le document d'entrée en relation. Ensuite vous pourrez valider votre compte en cliquant sur le lien que vous recevrez par mail.";
    case SIGNUP_STEPS.PASSWORD:
      return 'Veuillez renseigner votre mot de passe afin de finaliser la création de votre compte.';
    default:
      return '';
  }
};

export const formatAddress = (address: string) => {
  return address.replace(/\s*,/g, ',').replace(/\s*\./g, '.').trim();
};
