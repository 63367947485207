import React, { useContext, useState } from 'react';
import Checkbox from '@components/Checkbox';
import Cta from '@components/Cta';
import { HELPING_FILENAMES } from '@utils/utils';
import { validateSubscriptionDemand } from '@models/accountInitialization/apiRequests/ subscriptionRequests';
import { AccountInitializationContext } from '../utils/accountInitializationContext';

function SubscriptionValidation(): JSX.Element {
  const { updateStep } = useContext(AccountInitializationContext);

  const [validateCondition1IsChecked, setValidateCondition1] =
    useState<boolean>(false);
  const [validateCondition2IsChecked, setValidateCondition2] =
    useState<boolean>(false);
  const [validateCondition3IsChecked, setValidateCondition3] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    const response = await validateSubscriptionDemand();
    if (response) {
      updateStep(6);
    }
  };

  return (
    <div className="w-full lg:w-[32rem] mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup lg:p-[1.75rem] p-4 lg:space-y-8 space-y-6">
      <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
        Validation de ma souscription
      </h2>
      <Checkbox
        checked={validateCondition1IsChecked}
        label="Je valide avoir pris connaissance de "
        onChange={() => setValidateCondition1(!validateCondition1IsChecked)}
        labelLink="l'entrée en relation et la lettre de mission"
        fileLinked={HELPING_FILENAMES.letterOfMission}
      />
      <Checkbox
        checked={validateCondition2IsChecked}
        label="Je valide avoir pris connaissance du "
        onChange={() => setValidateCondition2(!validateCondition2IsChecked)}
        labelLink="rapport d'adéquation"
        fileLinked={HELPING_FILENAMES.adequacyReport}
      />
      <Checkbox
        checked={validateCondition3IsChecked}
        label="Je valide avoir pris connaissance du "
        onChange={() => setValidateCondition3(!validateCondition3IsChecked)}
        labelLink="profil investisseur associé au placement Poddle"
        fileLinked={HELPING_FILENAMES.investorProfile}
      />
      <div className="flex justify-center w-full">
        <Cta
          onClick={() => {
            handleSubmit();
          }}
          disabled={
            !validateCondition1IsChecked ||
            !validateCondition2IsChecked ||
            !validateCondition3IsChecked
          }
          label="Confirmer"
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default SubscriptionValidation;
