import { IUserStatus, IUserType } from '@utils/types';

export const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegEx = /^((\+)33|0)[1-9](\d{2}){4}$/;
export const numRegEx = /\D/;

export const logout = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  window.location.href = '/login';
};

export const truncate = (str: string, n: number) => {
  const isFile = str.includes('.');
  if (isFile) {
    const fileExtension = str.split('.').pop();
    const fileName = str.split('.').slice(0, -1).join('.');
    return fileName.length > n
      ? `${fileName.slice(0, n - 1)}...${fileExtension}`
      : str;
  }
  return str.length > n ? `${str.slice(0, n - 1)}...` : str;
};

export const HELPING_FILENAMES = {
  letterOfMission: '/pdf/Poddle_Lettre_de_Mission.pdf',
  adequacyReport: '/pdf/Poddle_Rapport_Adequation.pdf',
  investorProfile: '/pdf/Poddle_profil_investisseur.pdf',
};

export const USER_STATUS: { [key: string]: IUserStatus } = {
  // NOT_STARTED: 'Inscription non commencée', // ETAPE POPUP INITIALISATION DU COMPTE
  // DOCUMENTS_PENDING: 'Documents en attente de validation', // ETAPE TIMELINE Validation dossier inProgress
  // DOCUMENTS_VALIDATED: 'Documents validés', // ????
  // APPOINTMENT_PENDING: 'Prise de RDV ouverture signature', // ETAPE TIMELINE Prise de RDV ouverture signature inProgress
  // ACCOUNT_OPENING_SIGNATURE: 'Signature ouverture de compte', // ETAPE TIMELINE Signature ouverture de compte inProgress
  // INVESTMENT_SIGNATURE: 'Signature investissement', // ETAPE TIMELINE Signature investissement inProgress
  // COMPLETED: 'Inscription terminée', // Statut final
  SUBSCRIPTION_NOT_STARTED: 'Souscription non initiée', // ETAPE POPUP INITIALISATION DU COMPTE
  SUBSCRIPTION_STARTED: 'Souscription initiée', // Statut initial souscription
  SUBSCRIPTION_INITIAL_DEPOSIT: 'Dépôt initial', // Souscription, dépôt initial effectué
  SUBSCRIPTION_DOCUMENTS_UPLOADED: 'Documents déposés', // Souscription, documents déposés
  SUBSCRIPTION_IDENTITY_FORM: "Formulaire d'identité", // Souscription, formulaire identité
  SUBSCRIPTION_DEMAND: 'Demande de souscription', // ETAPE TIMELINE Validation dossier inProgress
  SUBSCRIPTION_DEMAND_VALIDATED: 'Souscription validée', // ETAPE TIMELINE Documents validés + affichage calendly
  APPOINTMENT_PENDING: 'Rendez-vous pris', // Rdv calendly pris
  APPOINTMENT_DONE: 'Rendez-vous ouverture', // ETAPE TIMELINE Prise de RDV ouverture signature inProgress
  ACCOUNT_OPENING_SIGNED: 'Ouverture de compte signée', // Signature ouverture
  ACCOUNT_OPENED: 'Compte ouvert', // ETAPE TIMELINE Compte ouvert inProgress
  ACCOUNT_CREDITED: 'Compte crédité', // ETAPE TIMELINE Compte crédité inProgress
  INVESTMENT_DONE: 'Investissement réalisé', // Statut final souscription
};

export const isUserInSubscription = (user: IUserType | null) => {
  if (!user) return false;
  return (
    user.status === USER_STATUS.SUBSCRIPTION_STARTED ||
    user.status === USER_STATUS.SUBSCRIPTION_INITIAL_DEPOSIT ||
    user.status === USER_STATUS.SUBSCRIPTION_DOCUMENTS_UPLOADED ||
    user.status === USER_STATUS.SUBSCRIPTION_IDENTITY_FORM
  );
};

export const findValidatedStatus = (
  user: IUserType | null,
  status: IUserStatus
) => {
  if (!user) return undefined;
  return user?.validated_account_status.completed.find(
    (item) => item.status === status
  );
};

export const formatDateDDMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const generateUniqueID = (length = 10) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
