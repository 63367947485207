import React, { useContext, useState } from 'react';
import SimpleInput from '@components/SimpleInput';
import Checkbox from '@components/Checkbox';
import Cta from '@components/Cta';
import TipPopup from '@components/TipPopup';
import { ContentCopy } from '@mui/icons-material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SpinLoader from '@components/SpinLoader';
import { GlobalContext } from '../../../../context/globalContext';

function ContentCopyIcon(props: any) {
  return <ContentCopy {...props} />;
}

function SmileyIcon(props: any) {
  return <SentimentSatisfiedAltIcon {...props} />;
}

interface RecapBankingDepositProps {
  amount: string;
  title: string;
  ctaValidateOnClick: () => void;
  loading?: boolean;
  ctaBackLabel?: string;
  ctaBackOnClick?: () => void;
  infoChangePopup?: boolean;
}

function RecapBankingDeposit({
  amount,
  title,
  ctaValidateOnClick,
  loading,
  ctaBackLabel,
  ctaBackOnClick,
  infoChangePopup,
}: RecapBankingDepositProps): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const [validateConditionIsChecked, setValidateCondition] =
    useState<boolean>(false);
  const [validateCondition2IsChecked, setValidateCondition2] =
    useState<boolean>(false);
  const [recapValidate, setRecapValidate] = useState<boolean>(false);

  const recapInputs = (
    <>
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="uniquePayment"
        id="uniquePayment"
        label="Versement unique"
        placeholder=""
        value={`${amount} €`}
        type="text"
        readOnly
      />
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="depositMode"
        id="depositMode"
        label="Mode de dépôt"
        placeholder=""
        value="Virement bancaire"
        type="text"
        readOnly
      />
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="additionalFees"
        id="additionalFees"
        label="Frais additionnels"
        placeholder=""
        value="Gratuit"
        type="text"
        readOnly
      />
    </>
  );

  const bankingDetailsInputs = (
    <>
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="recipient"
        id="recipient"
        label="Destinataire"
        placeholder=""
        value="Oddo SA"
        type="text"
        readOnly
        copyable
      />
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="iban"
        id="iban"
        label="IBAN"
        placeholder=""
        value="FR123456789"
        type="text"
        readOnly
        copyable
      />
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="bic"
        id="bic"
        label="BIC"
        placeholder=""
        value="OD123456789"
        type="text"
        readOnly
        copyable
      />
      <SimpleInput
        setValue={(value) => console.log(value)}
        name="paymentReference"
        id="paymentReference"
        label="Référence de paiement"
        placeholder=""
        value="0987654321"
        type="text"
        readOnly
        copyable
      />
    </>
  );

  return (
    <div className="w-full lg:w-[32rem] mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup p-[1.75rem] space-y-4">
      {recapValidate && !isMobile && (
        <>
          <TipPopup
            IconComponent={ContentCopyIcon}
            title="Astuces"
            content="Cliquez simplement sur le picto de droite pour copier les infos bancaires et éviter lorsque vous renseignerez ces informations sur votre compte bancaire erreur"
            multiplePosition={infoChangePopup ? 1 : undefined}
          />
          {infoChangePopup && (
            <TipPopup
              IconComponent={SmileyIcon}
              title="Changement d'information"
              content="Votre versement intervient plus de 3 mois après le versement précédent ? Cela veut dire que votre Kbis n'est plus 'à jour'. Ne vous inquietez pas, Poddle s'est chargé de commander un nouveau KBIS ;-)"
              multiplePosition={2}
            />
          )}
        </>
      )}
      <div>
        <div>
          {ctaBackLabel && ctaBackOnClick && (
            <button
              type="button"
              className="text-neutral-500 lg:mb-0 mb-2"
              onClick={ctaBackOnClick}
            >
              {ctaBackLabel}
            </button>
          )}
          <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
            {title}
          </h2>
        </div>
        <p className="text-neutral-800 text-[.75rem] leading-[1.25rem]">
          {!recapValidate
            ? 'Récapitulatif'
            : 'Votre demande de dépôt de fond à bien été enregistré sur notre plateforme. Vous pouvez maintenant effecturer le virement auprès de votre banque en utilisant les informations bancaires ci-dessous.'}
        </p>
      </div>
      {!recapValidate ? (
        <>
          {recapInputs}
          <div className="text-neutral-600 lg:!mb-12">
            Votre banque peut vous facturer des frais pour ce service
          </div>
          <Checkbox
            checked={validateConditionIsChecked}
            label="Par la présente, j'accepte l'exécution anticipée de cette transaction. par conséquent, je renonce à mon droit de résilitation du contrat en vertu du paragraphe 11 du FAGG."
            onChange={() => setValidateCondition(!validateConditionIsChecked)}
            small
          />
        </>
      ) : (
        <>
          {bankingDetailsInputs}
          <div className="text-neutral-600 !mb-4">
            Veillez à inclure ce numéro de référence lors du dépôt. Autrement
            nous ne serons pas en mesure de le rattacher à votre compte Poddle.{' '}
            <br />
            <br />
            SI votre banque prend en charge les virements instantanés SEPA,
            votre transfert ne devrait prendre que quelques minutes. Sinon cela
            peut prendre de 1 à 3 jours ouvrables.
          </div>
          <Checkbox
            checked={validateCondition2IsChecked}
            label="Par la présente, je valide les conditions générale de Poddle concernant les versements additionnels."
            onChange={() => setValidateCondition2(!validateCondition2IsChecked)}
            small
          />
        </>
      )}

      <div className="flex justify-center w-full">
        <Cta
          onClick={() => {
            if (!recapValidate) {
              setRecapValidate(true);
            } else {
              ctaValidateOnClick();
            }
          }}
          label="Valider mon versement"
          disabled={
            !recapValidate
              ? !validateConditionIsChecked
              : !validateCondition2IsChecked
          }
          addClass="mt-4"
        />
      </div>
      {loading && (
        <div className="flex justify-center">
          <SpinLoader />
        </div>
      )}
    </div>
  );
}

export default RecapBankingDeposit;

RecapBankingDeposit.defaultProps = {
  loading: false,
  ctaBackLabel: undefined,
  ctaBackOnClick: () => null,
  infoChangePopup: false,
};
