import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FolderIcon from '@mui/icons-material/Folder';
import logoPoddle from '@assets/images/logo_poddle.png';
import { logout, USER_STATUS } from '@utils/utils';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '@models/auth/utils/authContext';
import SidebarLink from '@components/sidebar/SideBarLink';

function SideBarLeft(): JSX.Element {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const isActive = (path: string) => location.pathname === path;

  const sidebarLinks = [
    {
      to: '/',
      label: 'Dashboard',
      icon: GridViewIcon,
      condition: user?.status !== USER_STATUS.SUBSCRIPTION_STARTED,
    },
    {
      to: '/deposits-and-withdrawals',
      label: 'Verser / Retirer',
      icon: TrendingUpIcon,
      condition: user?.status === USER_STATUS.INVESTMENT_DONE,
    },
    {
      to: '/documents',
      label: 'Documents',
      icon: FolderIcon,
      condition: user?.status !== USER_STATUS.SUBSCRIPTION_STARTED,
    },
  ];

  return (
    <div>
      {/* Bouton "burger" */}
      <button
        type="button"
        className="lg:hidden fixed top-6 right-6 z-[100]"
        onClick={toggleSidebar}
      >
        {isOpen ? (
          <CloseIcon
            sx={{
              fontSize: '2rem',
            }}
          />
        ) : (
          <MenuIcon
            sx={{
              fontSize: '2rem',
            }}
          />
        )}
      </button>

      {/* Logo Poddle mobile */}
      <div className="lg:hidden fixed z-50 w-full top-0 left-0 bg-white h-[5.5rem]">
        <Link to="/" className="relative top-3 left-6">
          <img className="w-[6.5625rem]" src={logoPoddle} alt="Logo Poddle" />
        </Link>
      </div>

      {/* Sidebar */}
      <div
        className={`${
          isOpen ? 'translate-x-0 ease-out' : 'translate-x-full ease-in'
        } fixed right-0 top-0 bottom-0 lg:h-screen w-[15.75rem] flex flex-col px-4 py-6 justify-between bg-white shadow-sidebar transition-transform duration-200 transform lg:relative lg:translate-x-0 z-50`}
      >
        {/* Contenu de la sidebar */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="flex flex-col lg:mt-0 mt-10" onClick={toggleSidebar}>
          <Link to="/" className="mb-7 hidden lg:block ml-6">
            <img className="w-[6.5625rem]" src={logoPoddle} alt="Logo Poddle" />
          </Link>
          <div className="flex flex-col space-y-2">
            {sidebarLinks.map(({ to, label, icon, condition }) => (
              <SidebarLink
                key={to}
                to={to}
                icon={icon}
                isActive={isActive(to)}
                isDisabled={!condition}
                onClick={(e) => {
                  if (!condition) {
                    e.preventDefault();
                  }
                }}
              >
                {label}
              </SidebarLink>
            ))}
          </div>
        </div>
        <div>
          <div className="flex">
            <div className="min-w-[2.125rem] min-h-[2.125rem] w-9 h-9 rounded-full bg-purple-800 text-white flex justify-center items-center text-[1rem] mr-3">
              {user?.name.charAt(0)}
            </div>
            <div className="max-w-[calc(100%-2.125rem-0.75rem)]">
              {/* Adjust width accordingly */}
              <p className="text-neutral-900 font-bold truncate">
                {user?.name} {user?.firstname}
              </p>
              <p className="text-neutral-500 text-[.75rem] truncate">
                {user?.email}
              </p>
            </div>
          </div>
          <div className="text-neutral-700 text-base mt-4">
            <button
              type="button"
              onClick={logout}
              className="text-neutral-600 underline"
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBarLeft;
