import React, { useContext } from 'react';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { formatDateDDMMYYYY } from '@utils/utils';
import { GlobalContext } from '../../../context/globalContext';

function TransactionsHistory(): JSX.Element {
  const { transactionsHistory, isMobile, balance } = useContext(GlobalContext);
  // Sert à limiter le nombre de transactions à afficher
  const NUMBER_OF_TRANSACTIONS_TO_DISPLAY = 15;
  const sortedTransactions = () => {
    // Transformer les dépôts
    const deposits = transactionsHistory.deposits.map((transaction) => ({
      icon: <TrendingUp style={{ color: 'green' }} />,
      type: 'Dépot',
      date: transaction.created_at,
      amount: transaction.amount,
      status: transaction.status,
    }));

    // Transformer les retraits
    const withdrawals = transactionsHistory.withdrawals.map((transaction) => ({
      icon: <TrendingDown style={{ color: 'red' }} />,
      type: 'Retrait',
      date: transaction.created_at,
      amount: transaction.amount,
      status: transaction.status,
    }));

    // Fusionner et trier les tableaux
    const combinedTransactions = [...deposits, ...withdrawals];

    return combinedTransactions.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="w-full">
        <h2 className="lg:text-[1.75rem] text-2xl font-epilogue font-semibold">
          Compte-titre
        </h2>
        <div className="w-full flex justify-center lg:mt-0 mt-4">
          <p className="text-2xl">
            Solde du compte :{' '}
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(parseFloat(balance))}
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="lg:w-1/2 w-full text-left table-fixed rounded-[4px] border">
          <table className="w-full text-left lg:table-fixed">
            <thead className="bg-primary-500 text-white">
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                {!isMobile && <th className="p-2 w-8" />}
                <th className="p-2">Type</th>
                <th className="p-2">Date</th>
                <th className="p-2">Montant</th>
                <th className="p-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions()
                .slice(0, NUMBER_OF_TRANSACTIONS_TO_DISPLAY)
                .map((transaction) => (
                  <tr key={transaction.date}>
                    {!isMobile && (
                      <td className="px-2 py-5 flex items-center justify-center">
                        {transaction.icon}
                      </td>
                    )}
                    <td
                      className={`px-2 py-5 font-semibold ${
                        isMobile &&
                        (transaction.type === 'Retrait'
                          ? 'text-red-600'
                          : 'text-green-600')
                      }`}
                    >
                      {transaction.type}
                    </td>
                    <td className="px-2 py-5">
                      {formatDateDDMMYYYY(transaction.date)}
                    </td>
                    <td className="px-2 py-5">{transaction.amount} €</td>
                    <td className="px-2 py-5">
                      <span
                        className={`px-3 py-1 rounded-full lg:text-sm text-xs whitespace-nowrap ${
                          transaction.status === 'En attente'
                            ? 'text-orange-700 bg-orange-100'
                            : 'text-success-700 bg-success-100'
                        }`}
                      >
                        {transaction.status}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TransactionsHistory;
