import React from 'react';

interface CheckboxProps {
  checked: boolean;
  label: string;
  onChange: () => void;
  small?: boolean;
  fileLinked?: string;
  labelLink?: string;
}

function Checkbox({
  checked,
  label,
  onChange,
  small,
  fileLinked,
  labelLink,
}: CheckboxProps) {
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div
      className="flex items-start space-x-[0.125rem] cursor-pointer"
      onClick={onChange}
    >
      <div className="relative mt-[0.125rem]">
        <input type="checkbox" checked={checked} className="sr-only" />
        <div
          className={`cursor-pointer outer-square border border-neutral-600 w-4 h-4 flex items-center justify-center rounded-sm ${
            checked ? 'bg-primary-500' : ''
          }`}
        >
          {checked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={`cursor-pointer ${
          small
            ? 'pl-1 text-xs leading-4 mt-[.125rem]'
            : 'pl-3 text-base leading-5'
        }`}
      >
        {label}
        {fileLinked && labelLink && (
          <a
            href={`${fileLinked}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-500 underline hover:hover:text-secondary-500"
            onClick={(e) => e.stopPropagation()}
          >
            {labelLink}
          </a>
        )}
      </label>
    </div>
  );
}

export default Checkbox;
Checkbox.defaultProps = {
  small: false,
  fileLinked: undefined,
  labelLink: undefined,
};
