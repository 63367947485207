import React, { useContext, useId, useState } from 'react';
import TimelinePoint from '@models/dashboard/components/TimelinePoint';
import { ITimelinePoint } from '@models/dashboard/utils/dashboardTypes';
import { findValidatedStatus, USER_STATUS } from '@utils/utils';
import { AuthContext } from '@models/auth/utils/authContext';
import { IUserStatus } from '@utils/types';
import { CheckIcon, TimerIcon } from '@assets/images/svgComponents';
import InfosPopup from '@components/InfosPopup';
import { GlobalContext } from '../../../context/globalContext';

function Timeline(): JSX.Element {
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(GlobalContext);
  const [popupIsPassed, setPopupIsPassed] = useState(false);
  const determineStatus = (
    currentStep: IUserStatus
  ): 'completed' | 'inProgress' | 'waiting' => {
    if (!user) return 'waiting';
    if (
      user?.validated_account_status.completed.find(
        (item) => item.status === currentStep
      )
    ) {
      return 'completed';
    }
    if (user?.validated_account_status.in_progress === currentStep) {
      return 'inProgress';
    }
    return 'waiting';
  };

  const timelinePoints: ITimelinePoint[] = [
    {
      status: determineStatus(USER_STATUS.SUBSCRIPTION_DEMAND),
      label: 'Demande de souscription',
      date: findValidatedStatus(user, USER_STATUS.SUBSCRIPTION_DEMAND)?.date,
    },
    {
      status: determineStatus(USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED),
      label: 'Demande de souscription validée',
      date:
        user?.status === USER_STATUS.SUBSCRIPTION_DEMAND
          ? ''
          : findValidatedStatus(user, USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED)
              ?.date,
    },
    {
      status: determineStatus(USER_STATUS.APPOINTMENT_DONE),
      label: 'Rendez-vous ouverture',
      date:
        user?.status === USER_STATUS.APPOINTMENT_PENDING
          ? ''
          : findValidatedStatus(user, USER_STATUS.APPOINTMENT_DONE)?.date,
    },
    {
      status: determineStatus(USER_STATUS.ACCOUNT_OPENED),
      label: 'Compte ouvert',
      date: findValidatedStatus(user, USER_STATUS.ACCOUNT_OPENED)?.date,
    },
    {
      status: determineStatus(USER_STATUS.ACCOUNT_CREDITED),
      label: 'Compte crédité',
      date: findValidatedStatus(user, USER_STATUS.ACCOUNT_CREDITED)?.date,
    },
    {
      status: determineStatus(USER_STATUS.INVESTMENT_DONE),
      label: 'Investissement réalisé',
      date: findValidatedStatus(user, USER_STATUS.INVESTMENT_DONE)?.date,
    },
  ];

  // Générez une liste d'IDs à l'avance
  const timelinePointIds = timelinePoints.map(() => useId());

  if (
    user?.status === USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED &&
    !popupIsPassed
  ) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <InfosPopup
          title="Votre dossier est validé et prêt pour la signature de l'ouverture de compte"
          text={[
            "Finalisez l'ouverture de votre compte-titres avec votre conseiller.",
          ]}
          ctaText={"C'est parti !"}
          onCtaClick={() => setPopupIsPassed(true)}
        />
      </div>
    );
  }

  if (user?.status === USER_STATUS.APPOINTMENT_PENDING) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <InfosPopup
          title="Votre RDV a bien été enregistré"
          text={
            'Une notification par email vient de vous être envoyée.\n' +
            'Vous serez recontacté sur le numéro de téléphone renseigné.\n' +
            "Si vous souhaitez indiquer un autre numéro de téléphone merci de nous l'indiquer dans le tchat."
          }
        />
      </div>
    );
  }

  if (user?.status === USER_STATUS.APPOINTMENT_DONE) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <InfosPopup
          title="Votre contrat de souscription est prêt à être signé. Vous pouvez le faire via docusign."
          text={
            'Consultez votre boite email et signez le docusign envoyé par notre partenaire Nortia.\n' +
            'Une question ? Notre équipe se tient à votre disposition dans le tchat.'
          }
          ctaText="Une question ?"
          onCtaClick={() => console.log('question')}
        />
      </div>
    );
  }

  if (user?.status === USER_STATUS.ACCOUNT_OPENING_SIGNED) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <InfosPopup
          title={
            'Votre compte a bien été ouvert !\n' +
            'Votre RIB va vous être envoyé par email.'
          }
          text={
            'Vous pouvez maintenant procéder au versement sur le RIB du compte que vous avez reçu sur votre boite email.\n' +
            'Une question ? Notre équipe se tient à votre disposition dans le tchat.'
          }
          ctaText="Une question ?"
          onCtaClick={() => console.log('question')}
        />
      </div>
    );
  }

  return (
    <div className="w-full lg:mt-20 lg:border-none border border-primary-500 border-2 lg:p-0 p-6 rounded">
      <div className="flex flex-1 lg:flex-row flex-col">
        <div className="flex flex-col flex-1 gap-5 lg:mb-0 mb-4">
          <h2 className="lg:text-[1.75rem] text-2xl font-epilogue font-semibold">
            Avancée de ma souscription
          </h2>
          {user &&
            (user.status === USER_STATUS.SUBSCRIPTION_DEMAND ||
              user.status === USER_STATUS.APPOINTMENT_PENDING ||
              user.status === USER_STATUS.ACCOUNT_OPENED) && (
              <div className="w-full flex justify-center items-center px-3 py-4 flex text-green-500 bg-[#D9F3DD] lg:w-min lg:whitespace-nowrap rounded">
                {user.status === USER_STATUS.APPOINTMENT_PENDING ? (
                  <>
                    <CheckIcon className="w-6 h-6 mr-3" fill="#1DD75B" />
                    Rendez-vous pris
                  </>
                ) : (
                  <>
                    <TimerIcon className="w-6 h-6 mr-3" fill="#1DD75B" />
                    {user.status === USER_STATUS.SUBSCRIPTION_DEMAND ? (
                      <>
                        Votre dossier est en cours de traitement auprès de notre
                        équipe.
                        <br />
                        Vous serez notifié par email lorsqu&apos;il aura été
                        approuvé.
                      </>
                    ) : (
                      <>
                        Merci de procéder au versement des fonds sur votre
                        compte en utilisant le RIB envoyé par email.
                        <br />
                        Si vous avez déjà procédé au versement, merci de ne pas
                        tenir compte de ce message.
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          <div className="flex w-full h-full flex-col lg:flex-row relative">
            <div className="flex flex-1 lg:flex-row flex-col items-center lg:h-[10rem] lg:px-[10rem] lg:mt-11">
              {timelinePoints.map((point, index) => (
                <React.Fragment key={timelinePointIds[index]}>
                  <TimelinePoint
                    status={point.status}
                    label={point.label}
                    date={point.date}
                    dateColor={point.dateColor}
                    position={index % 2 === 0 || isMobile ? 'top' : 'bottom'}
                  />
                  {index !== timelinePoints.length - 1 && !isMobile && (
                    <div className="h-4 bg-neutral-300 flex-1" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
