import React, { useState } from 'react';
import { CoffeeIcon } from '@assets/images/svgComponents';
import FloatingLabelInput from '@components/FloatingLabelInput';
import { emailRegEx } from '@utils/utils';
import SpinLoader from '@components/SpinLoader';
import { sendEmailPasswordForgotten } from '@models/auth/apiRequests/authRequests';

function ForgotPassword(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<string>('');

  const handleForgotPassword = async () => {
    setLoading(true);
    await sendEmailPasswordForgotten(userEmail);
    setLoading(false);
  };

  return (
    <div className="flex justify-center h-full items-center">
      <div className="hidden lg:flex w-1/2 pl-16 mt-[8rem] space-x-[5.25rem] self-start">
        <div className="flex flex-col items-end w-min">
          <CoffeeIcon width="3.75rem" height="3.75rem" fill="#8353E2" />
          <div className="w-[11rem] h-[0.5rem] bg-secondary-500 rounded-md mt-4" />
        </div>
        <div>
          <div className="font-epilogue text-5xl leading-[3.5rem] text-neutral-500">
            <p>
              Mot de passe <br /> oublié ?
            </p>
          </div>
          <div className="w-3/4 text-[.875rem] leading-[1.375rem] text-neutral-500 mt-2">
            Veuillez renseigner votre identifiant pour recevoir un lien de
            réinitialisation de votre mot de passe.
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 w-full flex lg:mt-[6rem] mt-8 flex space-x-12 lg:self-start">
        <div className="w-[28.75rem] py-14 px-12 border border-secondary-400 rounded">
          <h2 className="text-center font-epilogue font-bold text-[2rem] text-neutral-900 leading-[3rem] mb-8">
            Mot de passe oublié
          </h2>
          <div className="flex flex-col space-y-6">
            <FloatingLabelInput
              id="userEmail"
              name="userEmail"
              type="email"
              placeholder="Identifiant"
              value={userEmail}
              setValue={setUserEmail}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  userEmail &&
                  emailRegEx.test(userEmail)
                ) {
                  handleForgotPassword();
                }
              }}
            />
            <button
              disabled={userEmail === '' || !emailRegEx.test(userEmail)}
              onClick={handleForgotPassword}
              type="button"
              className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
            >
              C&apos;est parti !
            </button>
            {loading && (
              <div className="flex justify-center">
                <SpinLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
