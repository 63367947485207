import React, { useState } from 'react';

interface SimpleSwitchProps {
  option1: string;
  option2: string;
  onChangeOptionActive: (option1IsActive: boolean) => void;
}

function SimpleSwitch({
  option1,
  option2,
  onChangeOptionActive,
}: SimpleSwitchProps): JSX.Element {
  const [option1IsActive, setOption1IsActive] = useState(true);

  const toggleSwitch = () => {
    setOption1IsActive(!option1IsActive);
    onChangeOptionActive(!option1IsActive);
  };

  return (
    <button
      type="button"
      className="bg-primary-500 rounded-[6px] flex items-center font-inter text-sm p-[.125rem] cursor-pointer w-min"
      onClick={toggleSwitch}
    >
      <div
        className={`flex items-center justify-center rounded-[4px] px-4 py-[.375rem] leading-[.875rem] ${
          option1IsActive
            ? 'bg-white text-primary-500'
            : 'text-white bg-primary-500'
        }`}
      >
        {option1}
      </div>
      <div
        className={`flex items-center justify-center rounded-[4px] px-4 py-[.375rem] leading-[.875rem] ${
          !option1IsActive
            ? 'bg-white text-primary-500'
            : 'text-white bg-primary-500'
        }`}
      >
        {option2}
      </div>
    </button>
  );
}

export default SimpleSwitch;
