import { CoffeeIcon } from '@assets/images/svgComponents';
import FloatingLabelInput from '@components/FloatingLabelInput';
import { emailRegEx } from '@utils/utils';
import SpinLoader from '@components/SpinLoader';
import React, { useEffect, useState } from 'react';
import { resetPassword } from '@models/auth/apiRequests/authRequests';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [userPasswordConfirmation, setUserPasswordConfirmation] =
    useState<string>('');
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);

  const handleResetPassword = async () => {
    if (userPassword !== userPasswordConfirmation) {
      setPasswordsDontMatch(true);
      return;
    }
    setLoading(true);
    await resetPassword({
      email: userEmail,
      password: userPassword,
      password_confirmation: userPasswordConfirmation,
      token,
    });
    setLoading(false);
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    setPasswordsDontMatch(false);
  }, [userPassword, userPasswordConfirmation]);

  return (
    <div className="flex justify-center h-full items-center">
      <div className="hidden lg:flex w-1/2 pl-16 mt-[8rem] space-x-[5.25rem] self-start">
        <div className="flex flex-col items-end w-min">
          <CoffeeIcon width="3.75rem" height="3.75rem" fill="#8353E2" />
          <div className="w-[11rem] h-[0.5rem] bg-secondary-500 rounded-md mt-4" />
        </div>
        <div>
          <div className="font-epilogue text-5xl leading-[3.5rem] text-neutral-500">
            <p>
              Dernière étape <br /> avant de vous retrouver !
            </p>
          </div>
          <div className="w-3/4 text-[.875rem] leading-[1.375rem] text-neutral-500 mt-2">
            Veuillez renseigner votre identifiant et votre nouveau mot de passe
            pour retrouver votre compte.
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 w-full flex lg:mt-[6rem] mt-8 flex space-x-12 lg:self-start">
        <div className="lg:w-[28.75rem] w-full lg:py-14 py-7 lg:px-12 px-6 border border-secondary-400 rounded">
          <h2 className="text-center font-epilogue font-bold text-[2rem] text-neutral-900 leading-[3rem] mb-8">
            Réinitialisation du mot de passe
          </h2>
          <div className="flex flex-col space-y-6">
            <FloatingLabelInput
              id="userEmail"
              name="userEmail"
              type="email"
              placeholder="Identifiant"
              value={userEmail}
              setValue={setUserEmail}
            />
            <FloatingLabelInput
              id="userPassword"
              name="userPassword"
              type="password"
              placeholder="Nouveau mot de passe*"
              value={userPassword}
              setValue={setUserPassword}
            />
            <FloatingLabelInput
              id="userPasswordConfirmation"
              name="userPasswordConfirmation"
              type="password"
              placeholder="Confirmation du mot de passe*"
              value={userPasswordConfirmation}
              setValue={setUserPasswordConfirmation}
            />
            {passwordsDontMatch && (
              <div className="text-red-500 text-sm">
                Les mots de passe ne sont pas identiques
              </div>
            )}
            <div>
              <div className="text-xs">*12 caractères minimum</div>
              <button
                disabled={
                  userEmail === '' ||
                  userPassword === '' ||
                  userPassword.length < 12 ||
                  userPasswordConfirmation === '' ||
                  !emailRegEx.test(userEmail)
                }
                onClick={handleResetPassword}
                type="button"
                className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
              >
                C&apos;est parti !
              </button>
            </div>
            {loading && (
              <div className="flex justify-center">
                <SpinLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
