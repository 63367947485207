import React, { useContext, useId } from 'react';
import bgHeaderPopup from '@assets/images/bg_header_popup.png';
import Cta from '@components/Cta';
import InfosPopupMobile from '@components/InfosPopupMobile';
import { GlobalContext } from '../context/globalContext';

type InfosPopupProps = {
  title: string;
  text: string | string[];
  ctaText?: string;
  onCtaClick?: () => void;
};

export const newlineText = (text: string) =>
  text.split('\n').map((str, index, array) =>
    index === array.length - 1 ? (
      str
    ) : (
      <>
        {str}
        <br />
      </>
    )
  );
function InfosPopup({ title, text, ctaText, onCtaClick }: InfosPopupProps) {
  const { isMobile } = useContext(GlobalContext);

  if (isMobile) {
    return <InfosPopupMobile {...{ title, text, ctaText, onCtaClick }} />;
  }

  return (
    <div className="lg:w-[30rem] w-full mx-auto flex flex-col items-center bg-white rounded-[8px] shadow-infos-popup ">
      <img
        className="w-full h-[10rem] rounded-t-[8px]"
        src={bgHeaderPopup}
        alt="description"
      />

      <div className="flex flex-col items-center p-8 space-y-4 text-center">
        <h2 className="text-neutral-700 font-inter font-bold text-[1.25rem] leading-[1.875rem] lg:w-4/5">
          {newlineText(title)}
        </h2>

        {Array.isArray(text) ? (
          text.map((t) => (
            <p
              key={useId()}
              className="text-neutral-900 font-body text-[.875rem] leading-[1.375rem] w-full mb-[1rem]"
            >
              {t}
            </p>
          ))
        ) : (
          <p className="text-neutral-900 font-body text-[.875rem] leading-[1.375rem] w-full">
            {newlineText(text)}
          </p>
        )}

        {ctaText && onCtaClick && (
          <Cta
            label={ctaText}
            onClick={onCtaClick}
            addClass="mt-4 whitespace-normal w-full"
          />
        )}
      </div>
    </div>
  );
}

export default InfosPopup;

InfosPopup.defaultProps = {
  ctaText: undefined,
  onCtaClick: undefined,
};
