import React, { useContext, useState } from 'react';
import Cta from '@components/Cta';
import FileUploader from '@components/FileUploader';
import Checkbox from '@components/Checkbox';
import { documentTypeFromLabel } from '@models/documents/utils/utils';
import {
  tempSendDocument,
  validateDocuments,
} from '@models/documents/apiRequests/documentsRequests';
import { IDocumentToValidateType } from '@models/documents/utils/DocumentsTypes';
import SpinLoader from '@components/SpinLoader';
import { AccountInitializationContext } from '../utils/accountInitializationContext';
import { IFileRequired } from '../utils/accountInitializationTypes';
import { filesRequiredDirectorIdentity } from '../utils/utils';

function DirectorIdentity(): JSX.Element {
  const { updateStep } = useContext(AccountInitializationContext);

  const [filesRequired, setFilesRequired] = useState<IFileRequired[]>(
    filesRequiredDirectorIdentity
  );
  const [documentsToValidate, setDocumentsToValidate] = useState<
    IDocumentToValidateType[] | null
  >(null);

  const [validateConditionIsChecked, setValidateCondition] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFileUpload = async (file: File, name: string) => {
    const documentType = documentTypeFromLabel(name);
    if (documentType) {
      const newDocument = await tempSendDocument(file, documentType);
      if (newDocument) {
        setDocumentsToValidate((prevDocumentsToValidate) => {
          const updatedDocuments = (prevDocumentsToValidate || []).filter(
            (document) => document.type !== documentType
          );

          return updatedDocuments.concat({
            ...newDocument.data.data,
            documentType,
          });
        });
      }
    }
    setFilesRequired((prevFilesRequired) => {
      return prevFilesRequired.map((fileRequired) => {
        if (fileRequired.name === name) {
          return {
            ...fileRequired,
            file,
          };
        }
        return fileRequired;
      });
    });
  };

  const handleValidate = async () => {
    if (documentsToValidate) {
      setLoading(true);
      await validateDocuments(documentsToValidate);
      setLoading(false);
      updateStep(4);
    }
  };

  const onChangeFileUploadMultiple = (files: File[], name: string) => {
    const documentType = documentTypeFromLabel(name);
    if (documentType) {
      for (let i = 0; i < files.length; i += 1) {
        files.forEach(async (file) => {
          const newDocument = await tempSendDocument(file, documentType);
          if (newDocument) {
            setDocumentsToValidate((prevDocumentsToValidate) => {
              const updatedDocuments = prevDocumentsToValidate || [];
              return updatedDocuments.concat({
                ...newDocument.data.data,
                documentType,
              });
            });
          }
        });
        setFilesRequired((prevFilesRequired) => {
          return prevFilesRequired.map((fileRequired) => {
            if (fileRequired.name === name) {
              return {
                ...fileRequired,
                files,
              };
            }
            return fileRequired;
          });
        });
      }
    }
  };

  return (
    <div className="w-full lg:w-[35rem] mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup lg:p-[1.75rem] p-4 space-y-4 max-h-[80vh] overflow-y-scroll">
      <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
        Informations dirigeant
      </h2>
      {filesRequired.map((fileRequired) => (
        <FileUploader
          key={fileRequired.name}
          docName={fileRequired.name}
          acceptedFormat={fileRequired.acceptedFormat}
          fileType={fileRequired.type}
          onChangeFileUpload={(file) => {
            if (fileRequired.multiple) {
              onChangeFileUploadMultiple(file, fileRequired.name);
            } else {
              onChangeFileUpload(file, fileRequired.name);
            }
          }}
          description={fileRequired.description}
          labelLink={fileRequired.labelLink}
          link={fileRequired.link}
          multiple={fileRequired.multiple}
        />
      ))}

      <Checkbox
        checked={validateConditionIsChecked}
        label="Je certifie disposer de l'autorité nécessaire pour effectuer ce placement financier."
        onChange={() => setValidateCondition(!validateConditionIsChecked)}
      />

      {loading ? (
        <div className="flex justify-center">
          <SpinLoader />
        </div>
      ) : (
        <div className="flex justify-end w-full">
          <Cta
            onClick={handleValidate}
            label="Envoyer"
            disabled={
              !validateConditionIsChecked ||
              filesRequired.some((file) => !file.file && !file.files)
            }
            addClass="mt-4"
          />
        </div>
      )}
    </div>
  );
}

export default DirectorIdentity;
