import {
  IDepositData,
  ITransactionsLists,
  IWithdrawalData,
} from '@models/depositsAndWithdrawals/utils/depositsAndWithdrawalsTypes';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import { axiosClient } from '../../../api/apiClient';

export const getCurrentBalance = async (
  setCurrentBalance: Dispatch<SetStateAction<string>>
) => {
  try {
    const response = await axiosClient.get('/transfers/balance');
    setCurrentBalance(response.data.data.balance);
    return response.data.data;
  } catch (error: any) {
    toast.error(error.response.message || 'Une erreur est survenue');
    return null;
  }
};

export const deposit = async (
  data: IDepositData,
  updateBalance: Dispatch<SetStateAction<string>>
) => {
  try {
    const response = await axiosClient.post('/transfers/deposit', data);
    updateBalance(response.data.data.new_balance);
    toast.success('Dépôt effectué avec succès');
    return true;
  } catch (error: any) {
    toast.error(error.response.message || 'Une erreur est survenue');
    return false;
  }
};

export const withdraw = async (
  data: IWithdrawalData,
  updateBalance: Dispatch<SetStateAction<string>>
) => {
  try {
    const response = await axiosClient.post('/transfers/withdraw', data);
    updateBalance(response.data.data.new_balance);
    toast.success('Retrait effectué avec succès');
    return true;
  } catch (error: any) {
    toast.error(error.response.message || 'Une erreur est survenue');
    return false;
  }
};

export const getDepositAndWithdrawalHistory = async (
  setTransactionsHistory: Dispatch<SetStateAction<ITransactionsLists>>
) => {
  try {
    const response = await axiosClient.get('/transfers/history');
    setTransactionsHistory({
      withdrawals: response.data.data.withdrawals,
      deposits: response.data.data.deposits,
    });
    return true;
  } catch (error: any) {
    toast.error(error.response.message || 'Une erreur est survenue');
    return false;
  }
};
