import { Link } from 'react-router-dom';

interface ISidebarLinkProps {
  to: string;
  children: React.ReactNode;
  icon: React.ElementType;
  isActive: boolean;
  isDisabled: boolean;
  onClick: (e: React.MouseEvent) => void;
}
function SidebarLink({
  to,
  children,
  icon: IconComponent,
  isActive,
  isDisabled,
  onClick,
}: ISidebarLinkProps) {
  return (
    <Link
      to={to}
      className={`
      ${
        isActive
          ? 'bg-primary-200 text-primary-500 font-bold'
          : 'text-neutral-600'
      } 
       py-2 px-6 flex space-x-2 items-center rounded 
      ${
        isDisabled ? 'text-gray-400 cursor-default' : ''
      } hover:text-primary-500 hover:bg-primary-200`}
      onClick={onClick}
    >
      <IconComponent className={isDisabled ? 'text-gray-400' : ''} />
      <span>{children}</span>
    </Link>
  );
}

export default SidebarLink;
