// eslint-disable-next-line no-shadow
export enum DocumentTypeEnum {
  KBIS = 'Kbis',
  LF = 'Liasse Fiscale',
  SE = 'Statuts entreprise',
  RIB = 'RIB',
  RBE = 'RBE',
  CNI = "Carte d'identité",
  JDD = 'Justificatif de domicile',
}

export const documentTypeFromLabel = (label: string) => {
  switch (label) {
    case DocumentTypeEnum.KBIS:
      return 'KBIS';
    case DocumentTypeEnum.LF:
      return 'LF';
    case DocumentTypeEnum.SE:
      return 'SE';
    case DocumentTypeEnum.RIB:
      return 'RIB_WITHDRAWAL';
    case DocumentTypeEnum.RBE:
      return 'RBE';
    case DocumentTypeEnum.CNI:
      return 'CNI';
    case DocumentTypeEnum.JDD:
      return 'JDD';
    default:
      return false;
  }
};
