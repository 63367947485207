import { Dispatch, SetStateAction } from 'react';
import {
  ICompanyData,
  ILoginData,
  IRegisterData,
  IRegisterDataStep2,
  IResetPasswordData,
} from '@models/auth/utils/authTypes';
import { IUserType } from '@utils/types';
import { toast } from 'react-toastify';
import { axiosClient } from '../../../api/apiClient';

export const login = async (
  data: ILoginData,
  updateToken: Dispatch<SetStateAction<string | null>>
) => {
  try {
    const response = await axiosClient.post('login', data);
    // Si la réponse contient un token, on le stocke dans le local storage
    if (response.data.access_token) {
      updateToken(response.data.access_token);
      localStorage.setItem('token', response.data.access_token);
      axiosClient.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
      // indique que l'utilisateur est connecté
      return true;
    }
  } catch (error: any) {
    return false;
  }
  return false;

  // indique que la connexion a échouée
};

export const register = async (data: IRegisterData) => {
  try {
    await axiosClient.post('register', data);
    return true;
  } catch (error) {
    return false;
  }
};

export const registerStepSetPassword = async (
  data: IRegisterDataStep2,
  updateToken: Dispatch<SetStateAction<string | null>>
) => {
  try {
    const response = await axiosClient.post('register/set-password', data);
    console.log(response);
    // Si la réponse contient un token, on le stocke dans le local storage
    if (response.data.access_token) {
      updateToken(response.data.access_token);
      localStorage.setItem('token', response.data.access_token);
      axiosClient.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
      // indique que l'utilisateur est connecté
      return true;
    }
  } catch (error: any) {
    return false;
  }
  return false;
};

export const getUserByToken = async (
  updateUser: Dispatch<SetStateAction<IUserType | null>>,
  setLoading?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const response = await axiosClient.get('users/me');
    updateUser(response.data.data);
    // updateUser({ ...response.data.data, is_initialized: true });
    if (setLoading) setLoading(false);
  } catch (error) {
    // vide car erreur gérée avec les intercepteurs
    if (setLoading) setLoading(false);
  }
};

export const getUserCompany = async (
  updateUserCompany: Dispatch<SetStateAction<ICompanyData | null>>
) => {
  try {
    const response = await axiosClient.get('users/me/company');
    updateUserCompany(response.data.data);
    return true;
  } catch (error) {
    return false;
  }
};

export const sendEmailPasswordForgotten = async (email: string) => {
  try {
    await axiosClient.post('/forgot-password', { email });
    toast.success('Un email vous a été envoyé');
    return true;
  } catch (error: any) {
    toast.error(error.response);
    return false;
  }
};

export const resetPassword = async (data: IResetPasswordData) => {
  try {
    await axiosClient.post('/reset-password', data);
    toast.success('Votre mot de passe a été modifié');
    return true;
  } catch (error: any) {
    toast.error(error.response);
    return false;
  }
};

export const isCompanyAlreadyRegistered = async (siret: string) => {
  try {
    await axiosClient.post(`/companies/siret/${siret}/is-registered`);
    return false;
  } catch (error: any) {
    toast.error(error.response);
    return true;
  }
};

// export const sendPasswordCode = async (
//   data: FieldValues,
//   setShowModal: Dispatch<SetStateAction<boolean>>
// ) => {
//   try {
//     await axiosClient.post(urlPostPwdSendCode, data);
//     setShowModal(true);
//     return true;
//   } catch (error) {
//     displayError(error.response);
//     return false;
//   }
// };
//
// export const activatePasswordReset = async (
//   data: FieldValues,
//   setShowModal: Dispatch<SetStateAction<boolean>>
// ) => {
//   try {
//     await axiosClient.post(urlPostActivateResetPwd, data);
//     setShowModal(false);
//     toast.success(t('auth.reset_pwd.success_logged'));
//     return true;
//   } catch (error) {
//     displayError(error.response);
//     return false;
//   }
// };
