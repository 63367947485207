import React, { useContext, useState } from 'react';
import SimpleInput from '@components/SimpleInput';
import Checkbox from '@components/Checkbox';
import Cta from '@components/Cta';
import { GlobalContext } from '../../../../context/globalContext';

interface AmountDepositProps {
  title: string;
  ctaValidateOnClick: (amount: string) => void;
  ctaBackLabel?: string;
  ctaBackOnClick?: () => void;
}

function AmountDeposit({
  title,
  ctaValidateOnClick,
  ctaBackLabel,
  ctaBackOnClick,
}: AmountDepositProps): JSX.Element {
  const { isMobile, userCompany } = useContext(GlobalContext);
  const [amount, setAmount] = useState<string>('');
  const [validateConditionIsChecked, setValidateCondition] =
    useState<boolean>(false);

  return (
    <div className="lg:w-[32rem] w-full mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup p-[1.75rem] space-y-6 lg:space-y-8">
      <div>
        <div>
          {ctaBackLabel && ctaBackOnClick && (
            <button
              type="button"
              className="text-neutral-500 lg:mb-0 mb-2"
              onClick={ctaBackOnClick}
            >
              {ctaBackLabel}
            </button>
          )}
          <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
            {title}
          </h2>
        </div>
        <p className="text-neutral-800 text-[0.75rem] leading-[1.25rem]">
          Indiquez le montant que vous souhaitez verser sur votre compte-titre
          Poddle.
        </p>
      </div>
      <SimpleInput
        setValue={(value) => setAmount(value)}
        name="amount"
        id="amount"
        label="Montant investissement"
        placeholder={
          isMobile
            ? 'Renseignez le montant'
            : 'Renseignez le montant du versement'
        }
        value={amount}
        type="number"
        symbole="€"
      />
      <p className="text-neutral-600">
        Nous n&apos;acceptons que les dépôts provenant de comptes bancaires au
        nom de votre société. <br />
        Les noms provenants d&apos;un compte qui n&apos;est pas celui de votre
        entreprise seront retournés..
      </p>
      <Checkbox
        checked={validateConditionIsChecked}
        label={`Je certifie utiliser le compte bancaire de ${userCompany?.name}`}
        onChange={() => setValidateCondition(!validateConditionIsChecked)}
      />
      <div className="flex justify-center w-full">
        <Cta
          onClick={() => ctaValidateOnClick(amount)}
          label="Étape suivante"
          disabled={!validateConditionIsChecked || amount === ''}
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default AmountDeposit;

AmountDeposit.defaultProps = {
  ctaBackLabel: undefined,
  ctaBackOnClick: () => null,
};
