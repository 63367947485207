import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignUp from '@pages/SignUp';
import Login from '@pages/Login';
import Dashboard from '@pages/Dashboard';
import DepositsAndWithdrawals from '@pages/DepositsAndWithdrawals';
import Documents from '@pages/Documents';
import ForgotPassword from '@pages/ForgotPassword';
import ResetPassword from '@pages/ResetPassword';
import { AuthContext } from '@models/auth/utils/authContext';
import { USER_STATUS } from '@utils/utils';

function Router(): JSX.Element {
  const { token, user } = useContext(AuthContext);

  return token ? (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route
        path="/deposits-and-withdrawals"
        element={
          user?.status === USER_STATUS.INVESTMENT_DONE ? (
            <DepositsAndWithdrawals />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/documents"
        element={
          user?.status !== USER_STATUS.NOT_STARTED ? (
            <Documents />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default Router;
