import React from 'react';
import { createRoot } from 'react-dom/client';
import './style/tailwind.css';
import './style/global.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-TTXTK2L3',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
