import React, { useContext, useEffect, useMemo } from 'react';
import { AuthContext } from '@models/auth/utils/authContext';
import { USER_STATUS } from '@utils/utils';
import TransactionsHistory from '@models/dashboard/components/TransactionsHistory';
import { getDepositAndWithdrawalHistory } from '@models/depositsAndWithdrawals/apiRequests/depositsAndWithdrawalsRequests';
import Timeline from '@models/dashboard/components/Timeline';
import logoPoddle from '@assets/images/logo_poddle.png';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/globalContext';

function Dashboard(): JSX.Element {
  const {
    userDocuments,
    transactionsHistory,
    updateTransactionsHistory,
    isMobile,
  } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const componentRendered = useMemo(() => {
    if (!user) return <div />;
    switch (user?.status) {
      case USER_STATUS.INVESTMENT_DONE:
        if (
          transactionsHistory.deposits.length > 0 ||
          transactionsHistory.withdrawals.length > 0
        ) {
          return <TransactionsHistory />;
        }
        return <div />;
      default:
        if (userDocuments) return <Timeline />;
        return <div />;
    }
  }, [user, transactionsHistory, userDocuments]);

  useEffect(() => {
    if (user?.status === USER_STATUS.INVESTMENT_DONE) {
      getDepositAndWithdrawalHistory(updateTransactionsHistory);
    }
  }, [user?.status]);

  return (
    <div className="lg:px-[3.365rem] p-4 w-full lg:flex lg:flex-1">
      {componentRendered}
      {isMobile && user?.status !== USER_STATUS.INVESTMENT_DONE && (
        <div className="w-full flex justify-center mt-[4rem]">
          <Link to="/">
            <img className="w-[7.875rem]" src={logoPoddle} alt="logo poddle" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
