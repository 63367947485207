import React, { useContext, useEffect, useState } from 'react';
import Cta from '@components/Cta';
import FileUploader from '@components/FileUploader';
import SimpleSwitch from '@components/SimpleSwitch';
import {
  tempSendDocument,
  validateDocuments,
} from '@models/documents/apiRequests/documentsRequests';
import { IDocumentToValidateType } from '@models/documents/utils/DocumentsTypes';
import { documentTypeFromLabel } from '@models/documents/utils/utils';
import SpinLoader from '@components/SpinLoader';
import { AccountInitializationContext } from '../utils/accountInitializationContext';
import { IFileRequired } from '../utils/accountInitializationTypes';
import { filesRequiredCompanyInformations } from '../utils/utils';

function CompanyInformations(): JSX.Element {
  const { updateStep } = useContext(AccountInitializationContext);

  const [filesRequired, setFilesRequired] = useState<IFileRequired[]>(
    filesRequiredCompanyInformations
  );
  const [documentsToValidate, setDocumentsToValidate] = useState<
    IDocumentToValidateType[] | null
  >(null);
  const [isSoleBeneficiary, setIsSoleBeneficiary] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFileUpload = async (file: File, name: string) => {
    const documentType = documentTypeFromLabel(name);
    if (documentType) {
      const newDocument = await tempSendDocument(file, documentType);
      if (newDocument) {
        setDocumentsToValidate((prevDocumentsToValidate) => {
          const updatedDocuments = (prevDocumentsToValidate || []).filter(
            (document) => document.type !== documentType
          );

          return updatedDocuments.concat({
            ...newDocument.data.data,
            documentType,
          });
        });
      }
    }
    setFilesRequired((prevFilesRequired) => {
      return prevFilesRequired.map((fileRequired) => {
        if (fileRequired.name === name) {
          return {
            ...fileRequired,
            file,
          };
        }
        return fileRequired;
      });
    });
  };

  const rbeFile = filesRequired.find(
    (fileRequired) => fileRequired.name === 'RBE'
  );

  const handleValidate = async () => {
    if (documentsToValidate) {
      setLoading(true);
      await validateDocuments(documentsToValidate);
      setLoading(false);
      updateStep(3);
    }
  };

  useEffect(() => {
    if (!isSoleBeneficiary) {
      setFilesRequired((prevFilesRequired) => [
        ...prevFilesRequired,
        {
          name: 'RBE',
          acceptedFormat: 'pdf',
          type: 'pdf',
          description:
            'Téléchargez-le sur infogreffe. Il doit être de moins de 3 mois.',
        },
      ]);
    } else {
      setFilesRequired((prevFilesRequired) => {
        return prevFilesRequired.filter(
          (fileRequired) => fileRequired.name !== 'RBE'
        );
      });
    }
  }, [isSoleBeneficiary]);

  return (
    <div className="lg:w-[35rem] w-full mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup lg:p-[1.75rem] p-4 space-y-4 max-h-[80vh] overflow-y-scroll">
      <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
        Informations dirigeant
      </h2>
      {filesRequired
        .filter((file) => file.name !== 'RBE')
        .map((fileRequired) => (
          <FileUploader
            key={fileRequired.name}
            docName={fileRequired.name}
            acceptedFormat={fileRequired.acceptedFormat}
            fileType={fileRequired.type}
            onChangeFileUpload={(file) => {
              onChangeFileUpload(file, fileRequired.name);
            }}
            description={fileRequired.description}
            labelLink={fileRequired.labelLink}
            link={fileRequired.link}
          />
        ))}
      <div className="flex justify-between items-center w-full">
        <div className="text-neutral-900 text-sm">
          Je suis le seul bénéficiaire effectif
        </div>
        <SimpleSwitch
          option1="OUI"
          option2="NON"
          onChangeOptionActive={(option1IsActive) =>
            setIsSoleBeneficiary(option1IsActive)
          }
        />
      </div>
      {!isSoleBeneficiary && rbeFile && (
        <FileUploader
          docName={rbeFile.name}
          acceptedFormat={rbeFile.acceptedFormat}
          fileType={rbeFile.type}
          onChangeFileUpload={(file) => {
            onChangeFileUpload(file, rbeFile.name);
          }}
          description={rbeFile.description}
          labelLink={rbeFile.labelLink}
          link={rbeFile.link}
        />
      )}

      {loading ? (
        <div className="flex justify-center">
          <SpinLoader />
        </div>
      ) : (
        <div className="flex justify-end w-full space-x-4">
          {/* <Cta
            onClick={() => {
              console.log('save');
            }}
            label="Enregistrer"
            small
            transparent
            addClass="mt-4"
          /> */}
          <Cta
            onClick={handleValidate}
            label="Envoyer"
            small
            disabled={filesRequired.some((file) => !file.file)}
            addClass="mt-4"
          />
        </div>
      )}
    </div>
  );
}

export default CompanyInformations;
