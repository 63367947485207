import { useState } from 'react';
import ChooseDepositType from './ChooseDepositType';
import UniqueDeposit from './UniqueDeposit';

function Deposits(): JSX.Element {
  const [depositType, setDepositType] = useState<'unique' | 'recurring' | null>(
    null
  );

  switch (depositType) {
    case 'unique':
      return <UniqueDeposit setDepositType={setDepositType} />;
    case 'recurring':
      return <>recurring</>;
    case null:
    default:
      return <ChooseDepositType setDepositType={setDepositType} />;
  }
}

export default Deposits;
