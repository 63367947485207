import React, { useState } from 'react';

interface SliderProps {
  defaultValue?: number;
  onChange?: (value: number) => void;
}

function Slider({ defaultValue = 50, onChange }: SliderProps): JSX.Element {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
    if (onChange) {
      onChange(Number(event.target.value));
    }
  };

  return (
    <div className="w-full flex space-x-2">
      <div className="text-xs">{value}%</div>
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        className="slider flex-grow"
      />
    </div>
  );
}

export default Slider;

Slider.defaultProps = {
  onChange: undefined,
  defaultValue: 50,
};
