import { createContext, Dispatch, SetStateAction } from 'react';
import { IUserType } from '@utils/types';

export interface AuthContextState {
  token: string | null;
  updateToken: Dispatch<SetStateAction<string | null>>;
  user: IUserType | null;
  updateUser: Dispatch<SetStateAction<IUserType | null>>;
}

export const AuthContext = createContext({} as AuthContextState);
