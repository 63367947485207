import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import {
  IDocumentsArraysType,
  IDocumentToValidateType,
} from '@models/documents/utils/DocumentsTypes';
import { axiosClient } from '../../../api/apiClient';

export const getDocuments = async () => {
  try {
    const response = await axiosClient.get('/documents');
    return response.data.data;
  } catch (error: any) {
    toast.error(error.response);
    return null;
  }
};

export const tempSendDocument = async (file: File, type: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  try {
    return await axiosClient.post('/documents/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error: any) {
    toast.error("Un problème est survenu lors de l'envoi du document");
    return undefined;
  }
};

export const validateDocuments = async (
  documentsToValidate: IDocumentToValidateType[],
  setUserDocuments?: Dispatch<SetStateAction<IDocumentsArraysType | undefined>>
) => {
  try {
    const response = await axiosClient.post('/documents/upload/confirm', {
      documents: documentsToValidate,
    });

    if (setUserDocuments) {
      setUserDocuments(response.data.data);
    }
    if (documentsToValidate.length > 1) {
      toast.success('Documents envoyés avec succès');
    } else {
      toast.success('Document envoyé avec succès');
    }
  } catch (error: any) {
    toast.error(error.response);
  }
};
