import { ICustomerIdentityDetails } from '@models/accountInitialization/utils/accountInitializationTypes';
import { toast } from 'react-toastify';
import { axiosClient } from '../../../api/apiClient';

export const setCustomerIdentityDetails = async (
  data: ICustomerIdentityDetails
) => {
  try {
    await axiosClient.post('/users/me/identity-details', data);
    toast.success('Vos informations ont bien été enregistrées');
    return true;
  } catch (error: any) {
    toast.error(error.response);
    return false;
  }
};

export const validateSubscriptionDemand = async () => {
  try {
    await axiosClient.post('/users/validate-subscription-demand');
    toast.success('Votre demande de souscription a bien été enregistrée');
    return true;
  } catch (error: any) {
    toast.error(error.response);
    return false;
  }
};
