import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import InfosPopup from '@components/InfosPopup';
import { deposit } from '@models/depositsAndWithdrawals/apiRequests/depositsAndWithdrawalsRequests';
import { useNavigate } from 'react-router-dom';
import AmountDeposit from './AmountDeposit';
import RecapBankingDeposit from './RecapBankingDeposit';
import { GlobalContext } from '../../../../context/globalContext';

interface UniqueDepositProps {
  setDepositType: Dispatch<SetStateAction<'unique' | 'recurring' | null>>;
}

function UniqueDeposit({ setDepositType }: UniqueDepositProps): JSX.Element {
  const navigate = useNavigate();
  const { updateBalance } = useContext(GlobalContext);
  const [amount, setAmount] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const handleDeposit = async () => {
    setLoading(true);
    const depositSuccess = await deposit(
      {
        amount: parseInt(amount, 10),
        transfer_mode: 'WIRE',
      },
      updateBalance
    );
    if (depositSuccess) {
      setStep(3);
      setAmount('');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (amount) {
      handleDeposit();
    }
  }, [amount]);

  switch (step) {
    case 2:
      return (
        <RecapBankingDeposit
          amount={amount}
          ctaValidateOnClick={() => handleDeposit()}
          title="Versement Unique"
          ctaBackLabel="Revenir aux types de versements"
          ctaBackOnClick={() => setDepositType(null)}
          infoChangePopup
          loading={loading}
        />
      );
    case 3:
      return (
        <InfosPopup
          title="Votre dépôt de fonds à bien été enregistré !"
          text="Vous souhaitez épargner chaque mois ? Renseignez un versement récurrent !"
          ctaText="Revenir au dashboard"
          onCtaClick={() => navigate('/')}
        />
      );
    case 1:
    default:
      return (
        <AmountDeposit
          ctaValidateOnClick={(amountToSet) => setAmount(amountToSet)}
          title="Versement Unique"
          ctaBackLabel="Revenir aux types de versements"
          ctaBackOnClick={() => setDepositType(null)}
        />
      );
  }
}

export default UniqueDeposit;
