import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Cta from '@components/Cta';
import AddIcon from '@mui/icons-material/Add';
import { USER_STATUS } from '@utils/utils';
import { AuthContext } from '@models/auth/utils/authContext';

function Header(): JSX.Element {
  const { user } = useContext(AuthContext);
  return (
    <div className="h-[5.5rem] w-full flex items-center py-8 px-4 justify-end">
      <div className="flex items-center">
        {user?.status === USER_STATUS.INVESTMENT_DONE && (
          <Link to="/deposits-and-withdrawals" className="flex items-center">
            <Cta
              onClick={() => null}
              label="Faire un virement"
              grey
              Icon={
                <AddIcon
                  sx={{
                    fontSize: '1.25rem',
                  }}
                  className="text-neutral-600 group-hover:text-primary-500"
                />
              }
              addClass="mr-3"
            />
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
