import React, { useContext, useEffect, useMemo, useState } from 'react';
import InfosPopup from '@components/InfosPopup';
import { logout, USER_STATUS } from '@utils/utils';
import { AuthContext } from '@models/auth/utils/authContext';
import { AccountInitializationContext } from '@models/accountInitialization/utils/accountInitializationContext';
import logoPoddle from '@assets/images/logo_poddle.png';
import AmountToInvest from '../models/accountInitialization/components/AmountToInvest';
import CompanyInformations from '../models/accountInitialization/components/CompanyInformations';
import DirectorIdentity from '../models/accountInitialization/components/DirectorIdentity';
import InvestorProfile from '../models/accountInitialization/components/InvestorProfile';
import SubscriptionValidation from '../models/accountInitialization/components/SubscriptionValidation';
import { GlobalContext } from '../context/globalContext';

function AccountInitialization(): JSX.Element {
  const { user } = useContext(AuthContext);
  const { getUserDatas, isMobile } = useContext(GlobalContext);
  const [step, setStep] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6>(0);
  const [amountToInvest, setAmountToInvest] = useState<string>('');

  useEffect(() => {
    switch (user?.status) {
      case USER_STATUS.SUBSCRIPTION_INITIAL_DEPOSIT:
        setStep(2);
        break;
      case USER_STATUS.SUBSCRIPTION_DOCUMENTS_UPLOADED:
        setStep(4);
        break;
      case USER_STATUS.SUBSCRIPTION_IDENTITY_FORM:
        setStep(5);
        break;
      default:
        break;
    }
  }, [user]);

  const accountInitializationContextValue = useMemo(
    () => ({
      step,
      updateStep: setStep,
      amountToInvest,
      updateAmountToInvest: setAmountToInvest,
    }),
    [step, amountToInvest]
  );

  const stepToDisplay = useMemo(() => {
    switch (step) {
      case 1:
        return <AmountToInvest />;
      case 2:
        return <CompanyInformations />;
      case 3:
        return <DirectorIdentity />;
      case 4:
        return <InvestorProfile />;
      case 5:
        return <SubscriptionValidation />;
      case 6:
        return (
          <InfosPopup
            title="Merci pour votre souscription !"
            text={[
              "Vous serez notifié par email dès que l'équipe Poddle aura validé les documents transmis.",
              "Vous pouvez consulter l'état d'avancement de votre investissement à tout moment depuis votre tableau de bord.",
            ]}
            ctaText={"C'est parti !"}
            onCtaClick={getUserDatas}
          />
        );
      // case 6:
      //   return <CodePhone />;
      // case 6:
      // return <PaymentFunds />;
      case 0:
      default:
        return (
          <div>
            <InfosPopup
              title="Félicitations votre compte Poddle a bien été créé !"
              text="Suivez ces 5 étapes afin de commencer à faire travailler vos excédents de trésorerie"
              ctaText={"C'est parti !"}
              onCtaClick={() => setStep(1)}
            />
          </div>
        );
    }
  }, [step]);

  return (
    <AccountInitializationContext.Provider
      value={accountInitializationContextValue}
    >
      <div className="lg:p-4 w-full h-full lg:mt-0 mt-8">
        <div className="w-full flex flex-col h-full">
          {step !== 0 && step !== 6 && (
            <p className="text-neutral-500 lg:mb-0 mb-2">Étape {step} / 5</p>
          )}
          <div
            className={`flex lg:flex-grow lg:items-center justify-center ${
              step !== 0 ? 'lg:mb-8' : 'lg:mb-32'
            }`}
          >
            {stepToDisplay}
          </div>
          {isMobile && (
            <div className="w-full flex flex-col items-center mt-[5rem]">
              <img
                className="w-[7.875rem]"
                src={logoPoddle}
                alt="logo poddle"
              />
              <button
                type="button"
                onClick={logout}
                className="text-black hover:underline"
              >
                Déconnexion
              </button>
            </div>
          )}
        </div>
      </div>
    </AccountInitializationContext.Provider>
  );
}

export default AccountInitialization;
