// src/components/FloatingLabelInput.tsx
import React, { useState, ChangeEvent } from 'react';
import { emailRegEx } from '@utils/utils';
import { Visibility, VisibilityOff, Check } from '@mui/icons-material';

interface FloatingLabelInputProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

function FloatingLabelInput({
  id,
  name,
  type,
  placeholder,
  value,
  setValue,
  onChange,
  readOnly,
  onKeyDown,
}: FloatingLabelInputProps): JSX.Element {
  const [isFocused, setFocus] = useState(false);
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isValidEmail, setEmailValidity] = useState(true);

  const handleFocus = () => {
    if (value === '') {
      setEmailValidity(true);
    }
    setFocus(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setFocus(false);
      setEmailValidity(true);
    }
    if (type === 'email') {
      setEmailValidity(emailRegEx.test(value));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    setValue(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown);
  };

  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        type={type === 'password' && isPasswordShown ? 'text' : type}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        className={`w-full text-[.875rem] px-3 py-2 bg-neutral-200 rounded focus:outline-none ${
          isFocused || value ? 'pt-5' : ''
        } ${
          !isValidEmail && type === 'email' && value !== ''
            ? 'border border-red-500'
            : ''
        } ${readOnly ? 'pl-8 text-neutral-400' : ''}`}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
      />
      {readOnly && (
        <span className="absolute left-3 top-[1.125rem] cursor-pointer w-4 h-4">
          <Check fontSize="inherit" />
        </span>
      )}
      {type === 'password' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span
          className={`w-4 h-4 absolute right-3 mt-3 cursor-pointer ${
            isFocused || value ? 'top-0' : 'top-[-.375rem]'
          }`}
          onClick={togglePasswordVisibility}
        >
          {isPasswordShown ? (
            <VisibilityOff fontSize="inherit" />
          ) : (
            <Visibility fontSize="inherit" />
          )}
        </span>
      )}
      <label
        htmlFor={id}
        className={`absolute left-3 top-0 mt-2 transition-all duration-200 text-neutral-900 font-medium ${
          isFocused || value ? 'text-xs transform -translate-y-1' : 'text-base'
        }`}
      >
        {placeholder}
      </label>
      {!isValidEmail && type === 'email' && value !== '' && (
        <span className="text-xs text-red-500">
          L&apos;adresse email n&apos;est pas valide
        </span>
      )}
    </div>
  );
}

export default FloatingLabelInput;

FloatingLabelInput.defaultProps = {
  onChange: undefined,
  onKeyDown: undefined,
  readOnly: false,
};
