import React, { useEffect, useMemo, useState } from 'react';
import { IUserType } from '@utils/types';
import HeaderAuth from '@components/HeaderAuth';
import SideBarLeft from '@components/sidebar/SideBarLeft';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AccountInitialization from '@pages/AccountInitialization';
import Header from '@components/Header';
import SpinLoader from '@components/SpinLoader';
import {
  getUserByToken,
  getUserCompany,
} from '@models/auth/apiRequests/authRequests';
import {
  getCurrentBalance,
  getDepositAndWithdrawalHistory,
} from '@models/depositsAndWithdrawals/apiRequests/depositsAndWithdrawalsRequests';
import { IDocumentsArraysType } from '@models/documents/utils/DocumentsTypes';
import { getDocuments } from '@models/documents/apiRequests/documentsRequests';
import { ICompanyData } from '@models/auth/utils/authTypes';
import {
  generateUniqueID,
  isUserInSubscription,
  USER_STATUS,
} from '@utils/utils';
import { ITransactionsLists } from '@models/depositsAndWithdrawals/utils/depositsAndWithdrawalsTypes';
import TagManager from 'react-gtm-module';
import { GlobalContext } from './context/globalContext';
import { AuthContext } from './models/auth/utils/authContext';

interface ILayoutProps {
  children: JSX.Element;
}

function Layout({ children }: ILayoutProps): JSX.Element {
  const location = useLocation();
  const storageToken =
    localStorage.getItem('rememberMe') === 'true'
      ? localStorage.getItem('token')
      : sessionStorage.getItem('token');

  const [token, setToken] = useState<string | null>(storageToken);
  const [user, setUser] = useState<IUserType | null>(null);
  const [userCompany, setUserCompany] = useState<ICompanyData | null>(null);
  const [userDocuments, setUserDocuments] = useState<IDocumentsArraysType>();
  const [transactionsHistory, setTransactionsHistory] =
    useState<ITransactionsLists>({ deposits: [], withdrawals: [] });

  const [loading, setLoading] = useState<boolean>(true);
  const [balance, setBalance] = useState<string>('0');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const getUserDocuments = async () => {
    const response = await getDocuments();
    if (response) {
      setUserDocuments(response);
    }
  };
  const getUserDatas = () => {
    getUserByToken(setUser, setLoading);
    getUserCompany(setUserCompany);
    getUserDocuments();
    getCurrentBalance(setBalance);
    getDepositAndWithdrawalHistory(setTransactionsHistory);
  };

  const globalContextValues = useMemo(
    () => ({
      balance,
      updateBalance: setBalance,
      isMobile,
      userCompany,
      updateUserCompany: setUserCompany,
      userDocuments,
      updateUserDocuments: setUserDocuments,
      transactionsHistory,
      updateTransactionsHistory: setTransactionsHistory,
      getUserDatas,
    }),
    [balance, isMobile, userDocuments, transactionsHistory]
  );

  const authContextValue = useMemo(
    () => ({
      token,
      updateToken: setToken,
      user,
      updateUser: setUser,
    }),
    [token, user]
  );

  const urlsWithoutSidebar = [
    '/signup',
    '/login',
    '/forgot-password',
    '/reset-password',
  ];
  const sidebarVisible =
    urlsWithoutSidebar.findIndex((url) => location.pathname.includes(url)) ===
    -1;

  useEffect(() => {
    if (token) {
      if (token !== storageToken) {
        if (localStorage.getItem('rememberMe') === 'true') {
          localStorage.setItem('token', token);
        } else {
          sessionStorage.setItem('token', token);
        }
      }
      setLoading(true);
      getUserDatas();
    } else {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 1024);
    });
    const userID = localStorage.getItem('user_unique_id');

    if (!token) {
      if (!userID) {
        const uniqueID = generateUniqueID();
        localStorage.setItem('user_unique_id', uniqueID);
        localStorage.setItem('visit_count', '1');
        TagManager.dataLayer({
          dataLayer: {
            event: 'first_visit',
            user_id: localStorage.getItem('user_unique_id'),
          },
          dataLayerName: 'PageDataLayer',
        });
      } else {
        const currentCount = parseInt(
          localStorage.getItem('visit_count') || '0',
          10
        );
        localStorage.setItem('visit_count', (currentCount + 1).toString());
        if (currentCount !== 0) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'returning_visit',
              user_id: userID,
              visit_count: localStorage.getItem('visit_count'),
            },
            dataLayerName: 'PageDataLayer',
          });
        }
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={authContextValue}>
      <GlobalContext.Provider value={globalContextValues}>
        {loading ? (
          <div className="min-h-screen flex w-full justify-center items-center">
            <SpinLoader />
          </div>
        ) : (
          <div
            className={`min-h-screen w-full ${sidebarVisible ? 'flex' : ''} ${
              (!isUserInSubscription(user) && !sidebarVisible) ||
              (isMobile &&
                sidebarVisible &&
                user?.status === USER_STATUS.INVESTMENT_DONE &&
                'lg:pt-0 pt-20')
            } lg:p-0 p-4`}
          >
            {!isMobile && (!sidebarVisible ? <HeaderAuth /> : <SideBarLeft />)}
            <div className="flex flex-col flex-grow">
              {!token || !isUserInSubscription(user) ? (
                <>
                  <div className="hidden lg:block">
                    {sidebarVisible && <Header />}
                  </div>
                  {children}
                </>
              ) : (
                <AccountInitialization />
              )}
              {isMobile && !sidebarVisible && <HeaderAuth />}
              {isMobile &&
                sidebarVisible &&
                user?.status === USER_STATUS.INVESTMENT_DONE && <SideBarLeft />}
            </div>
          </div>
        )}

        <ToastContainer />
      </GlobalContext.Provider>
    </AuthContext.Provider>
  );
}

export default Layout;
