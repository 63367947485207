import { axiosClient } from '../../../api/apiClient';

export const getCompanyDataWithSiret = async (siretNumber: string) => {
  try {
    const response = await axiosClient.get(`/companies/siret/${siretNumber}`);
    return response.data.data;
  } catch (error) {
    return false;
  }
};
