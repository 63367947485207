import React from 'react';

interface SelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

function CustomSelect({ options, value, onChange }: SelectProps): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative w-full">
      <select
        value={value}
        onChange={handleChange}
        className="appearance-none w-full py-2 pr-8 pl-3 pr-8 border border-gray-300 rounded-md"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 12l5-6H5l5 6z" />
        </svg>
      </div>
    </div>
  );
}

export default CustomSelect;
