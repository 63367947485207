import React, { useContext, useState } from 'react';
import { emailRegEx } from '@utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@components/Checkbox';
import SpinLoader from '@components/SpinLoader';
import { login } from '@models/auth/apiRequests/authRequests';
import TagManager from 'react-gtm-module';
import FloatingLabelInput from '../components/FloatingLabelInput';
import { AuthContext } from '../models/auth/utils/authContext';

function Login(): JSX.Element {
  const navigate = useNavigate();
  const { updateToken } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<string>('');
  const [rememberMeIsChecked, setRememberMe] = useState<boolean>(false);
  const [userPassword, setUserPassword] = useState<string>('');
  // const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLogin = async () => {
    setLoading(true);
    const isLogged = await login(
      { email: userEmail, password: userPassword },
      updateToken
    );
    setLoading(false);
    if (isLogged) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'login',
          user_id: localStorage.getItem('user_unique_id'),
          visit_count: localStorage.getItem('visit_count'),
          user_data: {
            email_address: userEmail,
          },
        },
        dataLayerName: 'PageDataLayer',
      };
      TagManager.dataLayer(tagManagerArgs);
      localStorage.setItem('visit_count', '0');
      // updateLayoutIsLoading(true);
      navigate('/', { replace: true });
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center h-full items-center">
      <div className="w-full flex lg:mt-[6rem] mt-8 space-x-12 justify-center">
        <div className="lg:w-[28.75rem] w-full lg:py-14 py-7 lg:px-12 px-6 border border-secondary-400 rounded">
          <h2 className="text-left font-epilogue font-bold text-[2rem] text-neutral-900 leading-[3rem] mb-8">
            C&apos;est bon de vous revoir !
          </h2>
          <div className="flex flex-col space-y-6">
            <FloatingLabelInput
              id="userEmail"
              name="userEmail"
              type="email"
              placeholder="Identifiant"
              value={userEmail}
              setValue={setUserEmail}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  userEmail &&
                  userPassword &&
                  emailRegEx.test(userEmail)
                ) {
                  handleLogin();
                }
              }}
            />
            <FloatingLabelInput
              id="userPassword"
              name="userPassword"
              type="password"
              placeholder="Nouveau mot de passe"
              value={userPassword}
              setValue={setUserPassword}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  userEmail &&
                  userPassword &&
                  emailRegEx.test(userEmail)
                ) {
                  handleLogin();
                }
              }}
            />

            {/* errorMessage && (
              <span className="text-xs text-red-500">{errorMessage}</span>
            ) */}
            <div className="lg:flex lg:justify-between">
              <Checkbox
                checked={rememberMeIsChecked}
                label="Se souvenir de moi"
                onChange={() => setRememberMe(!rememberMeIsChecked)}
              />
              <div className="mt-4 lg:mt-0">
                <Link
                  to="/forgot-password"
                  className="ml-auto text-primary-500 hover:underline"
                >
                  Mot de passe oublié ?
                </Link>
              </div>
            </div>
            <button
              disabled={
                userEmail === '' ||
                userPassword === '' ||
                !emailRegEx.test(userEmail)
              }
              onClick={handleLogin}
              type="button"
              className="w-full px-4 py-2 mt-3 mx-auto text-white bg-primary-500 rounded shadow-lg font-body text-base disabled:opacity-40 transform hover:bg-primary-450 active:bg-primary-400 focus:outline-none"
            >
              C&apos;est parti !
            </button>
            {loading && (
              <div className="flex justify-center">
                <SpinLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
