import { useState } from 'react';
import Deposits from '../models/depositsAndWithdrawals/components/deposits/Deposits';
import Withdrawals from '../models/depositsAndWithdrawals/components/withdrawals/Withdrawals';

function DepositsAndWithdrawals(): JSX.Element {
  const [depositsOpen, setDepositsOpen] = useState(true);
  return (
    <div className="p-4 flex flex-col items-center">
      <div className="flex lg:w-[50rem] w-full lg:mt-16 my-8 lg:mb-[3.75rem]">
        <button
          type="button"
          onClick={() => setDepositsOpen(true)}
          className={`w-1/2 py-1 border border-primary-500 rounded rounded-r-none flex items-center justify-center ${
            depositsOpen
              ? 'bg-primary-500 text-white'
              : 'text-primary-500 bg-white'
          }`}
        >
          Versements
        </button>
        <button
          type="button"
          onClick={() => setDepositsOpen(false)}
          className={`w-1/2 py-1 border border-primary-500 rounded rounded-l-none flex items-center justify-center ${
            !depositsOpen
              ? 'bg-primary-500 text-white'
              : 'text-primary-500 bg-white'
          }`}
        >
          Retraits
        </button>
      </div>
      {depositsOpen ? <Deposits /> : <Withdrawals />}
    </div>
  );
}

export default DepositsAndWithdrawals;
