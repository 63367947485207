import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { numRegEx } from '@utils/utils';
import { ContentCopy, Check } from '@mui/icons-material'; // Assurez-vous d'installer @mui/icons-material

interface SimpleInputProps {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  label?: string;
  type?: string;
  setValue: (value: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  symbole?: string;
  small?: boolean;
  copyable?: boolean;
}

function SimpleInput({
  id,
  name,
  type,
  label,
  placeholder,
  value,
  setValue,
  onChange,
  readOnly,
  symbole,
  small,
  copyable,
}: SimpleInputProps): JSX.Element {
  const [copyIndicator, setCopyIndicator] = useState<boolean>(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number' && numRegEx.test(e.target.value)) {
      return;
    }

    if (type === 'phone' && !/^(?:\+|\d)*$/.test(e.target.value)) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
    setValue(e.target.value);
  };

  const handleCopy = (event: MouseEvent) => {
    event.preventDefault();
    setCopyIndicator(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopyIndicator(false);
    }, 1000);
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id} className="leading-[1.375rem] font-semibold mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          id={id}
          name={name}
          type={type === 'number' ? 'text' : type}
          onChange={handleChange}
          value={value}
          className={`w-full ${
            small ? 'text-[.75rem] px-2 py-1' : 'text-[.875rem] px-3 py-2'
          } bg-neutral-200 rounded focus:outline-none ${
            readOnly ? 'text-neutral-400' : ''
          }`}
          readOnly={readOnly}
          placeholder={placeholder}
        />
        {symbole && (
          <span className="absolute right-6 top-[0.5rem] cursor-pointer w-4 h-4">
            {symbole}
          </span>
        )}
        {copyable && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span
            onClick={handleCopy}
            className="absolute right-2 top-[0.5rem] cursor-pointer w-4 h-4"
          >
            {copyIndicator ? (
              <Check fontSize="inherit" />
            ) : (
              <ContentCopy fontSize="inherit" />
            )}
          </span>
        )}
      </div>
    </div>
  );
}

export default SimpleInput;

SimpleInput.defaultProps = {
  label: undefined,
  onChange: undefined,
  readOnly: false,
  type: 'text',
  symbole: undefined,
  small: false,
  copyable: false,
};
