import { useContext } from 'react';
import OneDocument from '../models/documents/components/OneDocument';
import { GlobalContext } from '../context/globalContext';

function Documents(): JSX.Element {
  const { userDocuments, updateUserDocuments } = useContext(GlobalContext);
  return (
    <div className="p-4 flex justify-center lg:mt-0">
      <div className="flex flex-col space-y-4">
        {userDocuments && userDocuments?.company.length > 0 && (
          <div>
            <h1 className="lg:text-[1.875rem] text-2xl font-epilogue font-semibold mb-4">
              Informations entreprise
            </h1>
            <div className="flex flex-col space-y-[1.75rem]">
              {userDocuments?.company.map((document) => (
                <OneDocument
                  key={document.id}
                  document={document}
                  setUserDocuments={updateUserDocuments}
                />
              ))}
            </div>
          </div>
        )}
        {userDocuments && userDocuments?.customer.length > 0 && (
          <div>
            <h1 className="lg:text-[1.875rem] text-2xl font-epilogue font-semibold mb-4 mt-6">
              Informations dirigeant
            </h1>
            <div className="flex flex-col space-y-[1.75rem]">
              {userDocuments?.customer.map((document) => (
                <OneDocument
                  key={document.id}
                  document={document}
                  setUserDocuments={updateUserDocuments}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Documents;
