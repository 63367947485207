import React, { useCallback, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { UploadCloud } from '@assets/images/svgComponents';
import { truncate } from '@utils/utils';

interface FileUploaderProps {
  docName: string;
  acceptedFormat: string;
  fileType: 'pdf' | 'image';
  onChangeFileUpload: (file: any) => void;
  description?: string;
  link?: string;
  labelLink?: string;
  multiple?: boolean;
  addClass?: string;
}

function FileUploader({
  docName,
  acceptedFormat,
  fileType,
  onChangeFileUpload,
  description,
  link,
  labelLink,
  multiple,
  addClass,
}: FileUploaderProps): JSX.Element {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>();

  const onDrop = useCallback((acceptedFiles: any) => {
    if (!multiple) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    } else {
      setSelectedFiles(acceptedFiles);
    }
  }, []);

  const accept: Accept =
    fileType === 'pdf'
      ? { 'application/pdf': [] }
      : {
          'image/jpeg': [],
          'image/png': [],
        };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  const renderFileNames = () => {
    if (multiple && selectedFiles) {
      return selectedFiles.map((file) => (
        <div key={file.name} className="truncate">
          {truncate(file.name, 15)}
        </div>
      ));
    }
    if (selectedFile) {
      return truncate(selectedFile.name, 15);
    }
    return null;
  };

  useEffect(() => {
    if (selectedFile && !multiple) {
      onChangeFileUpload(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFiles && multiple) {
      onChangeFileUpload(selectedFiles);
    }
  }, [selectedFiles]);

  return (
    <div
      className={`flex w-full lg:px-10 px-2 py-2 bg-neutral-100 border-2 border-dashed border-neutral-400 rounded text-neutral-500 text-[.75rem] cursor-pointer ${addClass} ${
        isDragActive || selectedFile || selectedFiles
          ? '!border-solid border-primary-500'
          : ''
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="w-3/4 pr-4">
        <div className="flex items-center mb-2 space-x-4">
          <UploadCloud width="2.25rem" height="2.25rem" />
          <div>
            <div className="text-neutral-900 font-semibold lg:text-[1.25rem] lg:leading-[1.875rem] text-[1rem]">
              {docName}
            </div>
            <div>Format accepté : {acceptedFormat}</div>
          </div>
        </div>
        {description && <div className="mb-2">{description}</div>}
        {link && labelLink && (
          <a
            href={link}
            className="underline"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {labelLink}
          </a>
        )}
      </div>
      <div className="w-1/4 text-center flex flex-col justify-center">
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedFile || selectedFiles ? (
          <div className="w-full text-left text-neutral-900 font-semibold">
            {renderFileNames()}
          </div>
        ) : isDragActive ? (
          <p>Déposez le fichier ici...</p>
        ) : (
          <p>Faites glisser le document ou recherchez sur votre ordinateur</p>
        )}
      </div>
    </div>
  );
}

export default FileUploader;

FileUploader.defaultProps = {
  description: undefined,
  link: undefined,
  labelLink: undefined,
  multiple: false,
  addClass: '',
};
