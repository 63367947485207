import { IFileRequired } from './accountInitializationTypes';

export const filesRequiredCompanyInformations: IFileRequired[] = [
  {
    name: 'Kbis',
    acceptedFormat: 'PDF',
    type: 'pdf',
    description: 'Attention le document doit dater de moins de 3 mois',
    labelLink: 'Comment obtenir un extrait de Kbis ?',
    link: 'https://www.demarches.interieur.gouv.fr/professionnels/comment-obtenir-extrait-k-kbis',
  },
  {
    name: 'Liasse Fiscale',
    acceptedFormat: 'PDF',
    type: 'pdf',
    labelLink: 'Où obtenir votre dernière liasse fiscale ?',
    link: 'https://www.impots.gouv.fr/formulaire/2050-liasse/liasse-fiscale-du-regime-reel-normal-en-matiere-de-bic-et-dis',
  },
  {
    name: 'Statuts entreprise',
    acceptedFormat: 'PDF',
    type: 'pdf',
    description:
      "Attention les status doivent être signés et certifiés conforme par le mandataire de l'entreprise",
  },
  {
    name: 'RIB',
    acceptedFormat: 'PDF',
    type: 'pdf',
    description:
      "Attention le rib doit être celui du compte de l'entreprise uniquement",
  },
];

export const filesRequiredDirectorIdentity: IFileRequired[] = [
  {
    name: "Carte d'identité",
    acceptedFormat: 'CNI, Carte de séjour, passeport',
    type: 'pdf',
    description:
      "Pensez bien à ajouter le recto et le verso de votre carte d'identité",
    multiple: true,
  },
  {
    name: 'Justificatif de domicile',
    acceptedFormat: 'edf, téléphone, etc.',
    type: 'pdf',
    description:
      'Pensez bien à ajouter le document en format PDF ou jpeg de moins de 3 mois',
  },
];

export const fiscalEntityTypes: string[] = [
  'SA',
  'SAS',
  'SASU',
  'SARL',
  'EURL',
  'EI',
  // 'EIRL',
  'SNC',
  'SCI',
  // 'SCS',
  // 'SCA',
  // 'SCEA',
  // 'SC',
  // 'GIE',
  // 'GIEE',
  // 'GEIE',
];
