import { createContext, Dispatch, SetStateAction } from 'react';
import { IDocumentsArraysType } from '@models/documents/utils/DocumentsTypes';
import { ICompanyData } from '@models/auth/utils/authTypes';
import { ITransactionsLists } from '@models/depositsAndWithdrawals/utils/depositsAndWithdrawalsTypes';

interface IProps {
  balance: string;
  updateBalance: Dispatch<SetStateAction<string>>;
  isMobile: boolean;
  userCompany: ICompanyData | null;
  updateUserCompany: Dispatch<SetStateAction<ICompanyData | null>>;
  userDocuments: IDocumentsArraysType | undefined;
  updateUserDocuments: Dispatch<
    SetStateAction<IDocumentsArraysType | undefined>
  >;
  getUserDatas: () => void;
  transactionsHistory: ITransactionsLists;
  updateTransactionsHistory: Dispatch<SetStateAction<ITransactionsLists>>;
}

export const GlobalContext = createContext({} as IProps);
