import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Cta from '@components/Cta';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FileUploader from '@components/FileUploader';
import {
  tempSendDocument,
  validateDocuments,
} from '@models/documents/apiRequests/documentsRequests';
import { documentTypeFromLabel } from '@models/documents/utils/utils';
import { Box, Button, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  IDocumentsArraysType,
  IDocumentToValidateType,
  IDocumentType,
} from '../utils/DocumentsTypes';

interface OneDocumentProps {
  document: IDocumentType;
  setUserDocuments: Dispatch<SetStateAction<IDocumentsArraysType | undefined>>;
}
function OneDocument({
  document,
  setUserDocuments,
}: OneDocumentProps): JSX.Element {
  const [editOpen, setEditOpen] = useState(false);
  const [fileToPush, setFileToPush] = useState<File | null>(null);
  const [documentToValidate, setDocumentToValidate] =
    useState<IDocumentToValidateType | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const handleValidate = () => {
    if (documentToValidate) {
      validateDocuments([documentToValidate], setUserDocuments);
    }
  };

  const storeDocument = async () => {
    if (fileToPush) {
      const documentType = documentTypeFromLabel(document.type_id);
      if (documentType) {
        const newDocument = await tempSendDocument(fileToPush, documentType);
        if (newDocument) {
          setDocumentToValidate({
            ...newDocument.data.data,
            documentType,
          });
        }
      }
    }
  };

  useEffect(() => {
    storeDocument();
  }, [fileToPush]);

  return (
    <div className="lg:w-[42rem] w-full">
      <div className="w-full py-[1.75rem] px-6 lg:flex justify-between shadow-infos-popup rounded lg:space-x-4">
        <div className="flex space-x-4 items-center">
          <CheckCircleRoundedIcon
            className="text-primary-500"
            sx={{ fontSize: '2.125rem' }}
          />
          <a
            target="_blank"
            href={`${process.env.REACT_APP_PODDLE_BASE_URL}/storage/documents/${document.filename}`}
            rel="noreferrer"
          >
            <span className="font-semibold text-[1.375rem] text-neutral-900">
              {document.type_id}
            </span>
          </a>
        </div>
        <div className="flex space-x-4 lg:mt-0 mt-4">
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'white',
                boxShadow: 10,
                borderRadius: 2,
                outline: 'none',
                p: 4,
              }}
            >
              <Button
                onClick={() => setOpenModal(false)}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'gray',
                }}
              >
                <CloseIcon />
              </Button>
              <div className="flex flex-col space-y-4 m-4">
                {document.cta_link}
              </div>
            </Box>
          </Modal>
          <Cta
            onClick={() => {
              if (editOpen) {
                setEditOpen(false);
              } else if (document.cta_link.length > 0) {
                setOpenModal(true);
              }
            }}
            label={editOpen ? 'Annuler' : document.cta_text}
            grey
          />
          <Cta
            onClick={() => {
              setEditOpen(!editOpen);
              if (editOpen) {
                handleValidate();
              }
            }}
            label={editOpen ? 'Valider' : 'Modifier'}
            disabled={editOpen && documentToValidate === null}
          />
        </div>
      </div>
      {editOpen && (
        <div className="mt-4">
          <FileUploader
            onChangeFileUpload={(file) => setFileToPush(file)}
            docName={document.type_id}
            acceptedFormat="PDF"
            fileType="pdf"
            addClass="mt-4 rounded"
          />
        </div>
      )}
    </div>
  );
}

export default OneDocument;
