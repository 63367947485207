import React, { useId } from 'react';
import bgHeaderPopupMobile from '@assets/images/bg_header_popup_mobile.jpeg';
import logoPoddle from '@assets/images/logo_poddle.png';
import Cta from '@components/Cta';
import { newlineText } from '@components/InfosPopup';
import { logout } from '@utils/utils';

type InfosPopupMobileProps = {
  title: string;
  text: string | string[];
  ctaText?: string;
  onCtaClick?: () => void;
};

function InfosPopupMobile({
  title,
  text,
  ctaText,
  onCtaClick,
}: InfosPopupMobileProps) {
  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-screen px-4 pt-[7rem] infos-popup-mobile overflow-hidden z-[20]">
        <div className="w-full mx-auto flex flex-col items-center bg-white rounded-[8px] shadow-infos-popup">
          <div className="flex flex-col items-center p-8 space-y-12 text-center">
            <h2 className="text-neutral-700 font-inter font-bold text-[1.25rem] leading-[1.875rem] lg:w-4/5">
              {newlineText(title)}
            </h2>

            {Array.isArray(text) ? (
              text.map((t) => (
                <p
                  key={useId()}
                  className="text-neutral-900 font-body text-[.875rem] leading-[1.375rem] w-full mb-[1rem]"
                >
                  {t}
                </p>
              ))
            ) : (
              <p className="text-neutral-900 font-body text-[.875rem] leading-[1.375rem] w-full">
                {newlineText(text)}
              </p>
            )}
            {ctaText && onCtaClick && (
              <Cta
                label={ctaText}
                onClick={onCtaClick}
                addClass="mt-4 whitespace-normal w-full"
              />
            )}
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          <img
            className="w-[7.875rem] mt-[9rem]"
            src={logoPoddle}
            alt="logo poddle"
          />
          <button
            type="button"
            onClick={logout}
            className="text-black hover:underline"
          >
            Déconnexion
          </button>
        </div>
      </div>
      <img
        className="bg-infos-popup-mobile absolute top-0 left-0 w-screen h-screen z-[10]"
        src={bgHeaderPopupMobile}
        alt="sunset"
      />
    </>
  );
}

export default InfosPopupMobile;

InfosPopupMobile.defaultProps = {
  ctaText: undefined,
  onCtaClick: undefined,
};
