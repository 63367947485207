import CardWithImage from '@components/CardWithImage';
import { Dispatch, SetStateAction } from 'react';

interface ChooseDepositTypeProps {
  setDepositType: Dispatch<SetStateAction<'unique' | 'recurring' | null>>;
}

function ChooseDepositType({
  setDepositType,
}: ChooseDepositTypeProps): JSX.Element {
  return (
    <div className="flex flex-col lg:flex-row lg:space-x-[5.25rem] lg:space-y-0 space-y-6">
      <CardWithImage
        title="Versement complémentaire"
        text="Effectuer un nouveau versement sur mon compte-titre Poddle"
        ctaLabel={"C'est parti !"}
        ctaOnClick={() => setDepositType('unique')}
      />
      {/* <CardWithImage */}
      {/* title="Versement récurrent" */}
      {/* text="Vous souhaitez ajouter un versement récurrent pour épargner chaque mois par exemple ? Coming soon !" */}
      {/* ctaLabel={"C'est parti !"} */}
      {/* ctaOnClick={() => setDepositType('recurring')} */}
      {/* ctaDisabled */}
      {/* /> */}
    </div>
  );
}

export default ChooseDepositType;
