import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import SimpleInput from '@components/SimpleInput';
import Checkbox from '@components/Checkbox';
import Cta from '@components/Cta';
import { GlobalContext } from '../../../../context/globalContext';

interface AmountWithdrawalProps {
  setAmount: (amount: string) => void;
  setStep: Dispatch<SetStateAction<number>>;
}

function AmountWithdrawal({
  setAmount,
  setStep,
}: AmountWithdrawalProps): JSX.Element {
  const { balance } = useContext(GlobalContext);
  const [amountToSet, setAmountToSet] = useState<string>('');
  const [validateConditionIsChecked, setValidateCondition] =
    useState<boolean>(false);

  const newAmount = useMemo(() => {
    return Number(balance) - Number(amountToSet);
  }, [amountToSet, balance]);

  return (
    <div className="lg:w-[32rem] w-full mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup p-[1.75rem] lg:space-y-8 space-y-4">
      <div>
        <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-2xl lg:leading-[3rem]">
          Faire un Retrait
        </h2>
        <p className="text-neutral-800 text-[0.75rem] leading-[1.25rem]">
          Veuillez indiquer les montants à retirer sur votre compte-titre Poddle
        </p>
      </div>
      <div className="lg:flex lg:space-x-2">
        <div className="lg:w-1/2">
          <SimpleInput
            setValue={() => null}
            name="currentAmount"
            id="currentAmount"
            label="Montant actuel"
            placeholder=""
            value={`${balance} €`}
            type="text"
            readOnly
          />
        </div>
        <div className="lg:w-1/2 lg:mt-0 mt-4">
          <SimpleInput
            setValue={(value) => {
              if (Number(value) > Number(balance)) {
                setAmountToSet(balance);
              } else {
                setAmountToSet(value);
              }
            }}
            name="amount"
            id="amount"
            label="Montant investissement"
            placeholder="Renseigner montant"
            value={amountToSet.toString()}
            type="number"
            symbole="€"
          />
        </div>
      </div>
      <SimpleInput
        setValue={() => null}
        name="newAmount"
        id="newAmount"
        label="Nouveau montant"
        placeholder=""
        value={`${newAmount} €`}
        type="text"
        readOnly
      />
      <p className="text-neutral-600">
        Attention retirer du capital va impacter le rendement de votre
        compte-titre. L&apos;appel de fonds sera déclenché suite à la validation
        de cette étape. Vos fonds seront disponible sur votre compte bancaire
        renseigné dans l&apos;app sous 3 à 4 jours ouvrés
      </p>
      <Checkbox
        checked={validateConditionIsChecked}
        label="J'ai pris connaissance des conditions générales pour le retrait sur le compte-titre. "
        fileLinked={`${process.env.REACT_APP_PODDLE_BASE_URL}/Conditions_Générales_Poddle.pdf`}
        labelLink="Voir les conditions générales"
        onChange={() => setValidateCondition(!validateConditionIsChecked)}
      />
      <div className="flex justify-center w-full">
        <Cta
          onClick={() => {
            setAmount(amountToSet);
            setStep(2);
          }}
          label="Valider mon retrait"
          disabled={!validateConditionIsChecked || amountToSet === ''}
          addClass="mt-4"
        />
        <Cta
          onClick={() => {
            setAmountToSet('');
            setValidateCondition(false);
          }}
          label="Annuler"
          transparent
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default AmountWithdrawal;
