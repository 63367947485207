import React, { Dispatch, SetStateAction, useContext } from 'react';
import Cta from '@components/Cta';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { GlobalContext } from '../../../../context/globalContext';

interface WarningWithdrawalProps {
  setStep: Dispatch<SetStateAction<number>>;
  onValidateWithdraw: () => void;
}

function WarningWithdrawal({
  setStep,
  onValidateWithdraw,
}: WarningWithdrawalProps): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  return (
    <div className="lg:w-[25rem] w-full mx-auto flex flex-col bg-white rounded-[8px] shadow-infos-popup p-[1.75rem] lg:space-y-8 space-y-6">
      <div className="flex w-full justify-center">
        <div className="bg-[#C8F9FF] rounded-full p-2">
          <WarningAmberIcon
            sx={{ color: '#006D7C' }}
            width="2rem"
            height="2rem"
          />
        </div>
      </div>
      <h2 className="text-neutral-700 font-epilogue font-bold lg:text-[1.75rem] text-xl lg:leading-[3rem] text-center">
        Vous êtes sur le point{isMobile ? ' ' : <br />}de retirer vos fonds
      </h2>

      <p className="text-neutral-600 text-center">
        En cliquant sur valider vous allez déclencher une demande de retraits de
        fonds. Vous ne pourrez plus l&apos;annuler après la validation de cette
        étape.
      </p>
      <div className="flex justify-center w-full space-x-4">
        <Cta onClick={onValidateWithdraw} label="Confirmer" addClass="mt-4" />
        <Cta
          onClick={() => {
            setStep(1);
          }}
          label="Annuler"
          transparent
          addClass="mt-4"
        />
      </div>
    </div>
  );
}

export default WarningWithdrawal;
