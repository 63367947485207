import React, { useContext, useEffect, useState } from 'react';
import InfosPopup from '@components/InfosPopup';
import { useNavigate } from 'react-router-dom';
import { withdraw } from '@models/depositsAndWithdrawals/apiRequests/depositsAndWithdrawalsRequests';
import SpinLoader from '@components/SpinLoader';
import AmountWithdrawal from './AmountWithdrawal';
import WarningWithdrawal from './WarningWithdrawal';
import { GlobalContext } from '../../../../context/globalContext';

function Withdrawals(): JSX.Element {
  const navigate = useNavigate();
  const { updateBalance } = useContext(GlobalContext);
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (amount) {
      setStep(2);
    }
  }, [amount]);

  const handleWithdrawal = async () => {
    setLoading(true);
    const withdrawSuccess = await withdraw(
      {
        amount: parseInt(amount, 10),
      },
      updateBalance
    );
    setLoading(false);
    if (withdrawSuccess) {
      setStep(3);
    }
  };

  if (loading) {
    return <SpinLoader />;
  }

  switch (step) {
    case 3:
      return (
        <InfosPopup
          title="Votre demande de retrait de fonds à bien été enregistrée !"
          text="Ils seront disponibles sur votre compte bancaire professionel sous 4 jours ouvrés environ.
                Vous serez notifié de la réception des fonds"
          ctaText="Revenir au dashboard"
          onCtaClick={() => navigate('/')}
        />
      );
    case 2:
      return (
        <WarningWithdrawal
          onValidateWithdraw={handleWithdrawal}
          setStep={setStep}
        />
      );
    case 1:
    default:
      return <AmountWithdrawal setAmount={setAmount} setStep={setStep} />;
  }
}

export default Withdrawals;
