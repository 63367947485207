import React, { useContext } from 'react';
import { Check } from '@mui/icons-material';
import { USER_STATUS } from '@utils/utils';
import { AuthContext } from '@models/auth/utils/authContext';
import Cta from '@components/Cta';
import { ReservationIcon } from '@assets/images/svgComponents';
import { GlobalContext } from '../../../context/globalContext';

type TimelinePointProps = {
  status: 'completed' | 'inProgress' | 'waiting';
  label: string;
  date?: string;
  dateColor?: string;
  position: 'top' | 'bottom';
};

function TimelinePoint({
  status,
  label,
  date,
  dateColor,
  position,
}: TimelinePointProps): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  let pointClasses =
    'w-8 h-8 rounded-full flex items-center justify-center lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 lg:-translate-x-1/2';

  switch (status) {
    case 'completed':
      if (
        user?.status === USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED &&
        label !== USER_STATUS.APPOINTMENT_DONE
      ) {
        pointClasses += ' bg-neutral-300';
      } else {
        pointClasses += ' bg-primary-500';
      }
      break;
    case 'inProgress':
      pointClasses += ' border-4 border-primary-500 bg-white';
      break;
    case 'waiting':
    default:
      pointClasses += ' border-2 border-neutral-400 bg-white';
      break;
  }

  const waitingForExternalReservation =
    user?.status === USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED &&
    label === USER_STATUS.APPOINTMENT_DONE;

  const reservationCard = () => (
    <div className="p-4 shadow-sidebar rounded-[.5rem] font-bold font-epilogue leading-7 flex flex-col items-center">
      <p className="text-neutral-900 text-lg whitespace-nowrap">
        Réservez votre créneau
        <br />
        d&apos;ouverture de compte
      </p>
      <p className="mt-2 mb-6 text-neutral-650 font-normal">15 min</p>
      <div className="bg-secondary-150 rounded-full w-[8.25rem] h-[8.25rem] mb-4">
        <ReservationIcon />
      </div>
      <a
        href="https://app.lemcal.com/@thomas-poddle/15-minutes"
        target="_blank"
        rel="noreferrer"
      >
        <Cta
          onClick={() => console.log('new reservation')}
          label="Je réserve un créneau"
          outline
        />
      </a>
    </div>
  );

  const pointDescription = (
    <div
      className={`lg:absolute ${
        position === 'bottom' ? 'top-full lg:mt-6' : 'bottom-full lg:mb-6 mb-2'
      } text-center lg:w-[9.5rem] w-auto lg:-translate-x-1/2`}
    >
      <div
        className={
          user?.status === USER_STATUS.SUBSCRIPTION_DEMAND_VALIDATED &&
          label !== USER_STATUS.APPOINTMENT_DONE
            ? ' text-neutral-500'
            : ''
        }
      >
        {label}
      </div>
      {date && (
        <div className={`text-xs ${dateColor ?? 'text-gray-500'}`}>{date}</div>
      )}
    </div>
  );

  return (
    <div className="relative lg:block flex flex-col items-center">
      {position === 'top' && pointDescription}
      <div className={pointClasses}>
        {status === 'completed' && (
          <Check fontSize="small" className="text-white" />
        )}
      </div>
      {isMobile && (
        <div className="mb-4 w-full flex flex-col items-center">
          <div
            className={`${
              waitingForExternalReservation
                ? 'bg-primary-500'
                : 'bg-neutral-300'
            } h-[3.375rem] w-1`}
          />
          {waitingForExternalReservation && reservationCard()}
        </div>
      )}
      {position === 'bottom' && pointDescription}
      {!isMobile && waitingForExternalReservation && (
        <div className="absolute -translate-x-1/2 mt-4 flex flex-col items-center">
          <div className="bg-primary-500 h-[9rem] w-1" />
          {reservationCard()}
        </div>
      )}
    </div>
  );
}

TimelinePoint.defaultProps = {
  date: undefined,
  dateColor: undefined,
};

export default TimelinePoint;
